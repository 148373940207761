import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState, PageState } from '../interfaces';
import { dialogInfoState } from '../globalState';

interface PageStateProps {
  pageState: PageState;
  setPageState: React.Dispatch<React.SetStateAction<PageState>>;
}

export default function CommonPageNavi(props: PageStateProps) {

  const {pageState, setPageState} = props;


  return (
    <>
    {pageState.idx !== undefined && pageState.total !== undefined ?
    <div className='flexCenter' style={{gap: 5, marginTop: 60}}>
      {pageState.idx > 0 ? 
        <img src={`./src/ic_double_arrow_left.png`} className='pageArrow' alt="맨처음으로" onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? 0 : 0
          }))
        }}/> 
      : <div style={{width: 24}}/> }
      {pageState.idx > 0 ? 
        <img src={`./src/ic_arrow_left.png`} className='pageArrow' alt="앞으로" onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx-1 : 0
          }))
        }}/> 
        : <div style={{width: 24}}/> }

      {pageState.idx-1 > 0 ? 
        <div className={cn('pageBtn')} onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx-2 : 0
          }))
        }}>{pageState.idx-1}</div> 
      : null}
      {pageState.idx > 0 ? 
        <div className='pageBtn' onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx-1 : 0
          }))
        }}>{pageState.idx}</div>
      : null}

      <div className='pageBtn highlightedPageBtn'>{pageState.idx+1}</div>

      {pageState.idx+1 < pageState.total ? 
        <div className={cn('pageBtn')} onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx+1 : 0
          }))
        }}>{pageState.idx+2}</div> 
      : null}
      {pageState.idx+2 < pageState.total ? 
        <div className='pageBtn' onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx+2 : 0
          }))
        }}>{pageState.idx+3}</div>
      : null}



      {pageState.idx+1 < pageState.total ? 
        <img src={`./src/ic_arrow_right.png`} className='pageArrow' alt="뒤로" onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined ? prevState.idx+1 : 0
          }))
        }}/> 
        : <div style={{width: 24}}/> }
        {pageState.idx+1 < pageState.total ? 
        <img src={`./src/ic_double_arrow_right.png`} className='pageArrow' alt="맨끝으로"onClick={() => {
          setPageState((prevState) => ({
            ...prevState,
            idx: prevState.idx !== undefined && prevState.total !== undefined ? prevState.total-1 : 0
          }))
        }}/> 
        : <div style={{width: 24}}/> }
        </div>
    : null}
    </>

  );
}