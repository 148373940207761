import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { DialogInfoState, PageState, SearchState, SignupUserInfo, UserInfoData, UserRole } from '../../interfaces';
import * as API from '../../APIProvider'
import { dateToYMDHHMM, onClickSelectAll, openNotiDialogWithSetter, searchAndUpdateDataWithTargetKey, updateSelectAll } from '../../util';
import { cloneDeep } from 'lodash';
import CommonPageNavi from '../../components/CommonPageNavi';

export default function SignupRequestsPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })
  const [userData, setUserData] = useState<Array<UserInfoData>>();
  const [rawUserData, setRawUserData] = useState<Array<UserInfoData>>([]);

  const [roleData, setRoleData] = useState<Array<UserRole>>([]);
  const [checkedUsers, setCheckedUsers] = useState<Array<SignupUserInfo>>([]);

  const [searchState, setSearchState] = useState<SearchState>({
    keyword: '',
    targetKey: 'user_email'
  });

  const handleLoadSignupRequests = () => {
    API.getSignupRequests()
    .then((res) => {
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      })
      setUserData(res.data);
      setRawUserData(res.data);
      setCheckedUsers(res.data.map((item:UserInfoData) => {
        return ({
          checked: false,
          user_id: item.user_id,
          role_id: item.role_id
        })
      }))
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleLoadUserRole = () => {
    API.getUserRole()
    .then((res) => {
      setRoleData(res.data)
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleProcessSignupRequests = (agreed: boolean) => {
    try {
      checkedUsers.forEach((item, idx) => {
        if (!item.checked) return;

        API.updateSignupRequest(item.user_id, agreed, item.role_id)
        .then((res) => {
          console.log(res);
          setDialogState((prevState) => ({
            ...prevState,
            isOpen: true,
            title: agreed ? '회원가입 승인 처리를 완료했습니다.' : '회원가입 거부 처리를 완료했습니다.',
            onAccept: () => {
              setDialogState((prevState) => ({
                ...prevState,
                isOpen: false
              }))
              handleLoadSignupRequests();
            },
            onDeny: undefined
          }));
    
        })
        .catch((err) => {
          console.log(err);
          openNotiDialog('오류가 발생했습니다.');
    
        })
      })
    }
    catch (e) {
      console.log(e);
      openNotiDialog('오류가 발생했습니다.');
    }
  }

  useEffect(() => {
    handleLoadSignupRequests();
    handleLoadUserRole();
  }, [])

  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>가입 신청 목록</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '36px 49px'}}>
          <div className='flexStart inter800-16-20' style={{width: 1174}}>
           
            <select className={cn('squareSelect')} name="searchType" id="searchTypeSelect" 
              defaultValue={""}
              value={searchState.targetKey}
              style={{backgroundImage: 'url(./src/ic_select_arrow.png)'}}
              onChange={(e) => {
                setSearchState((prevState) => ({
                  ...prevState,
                  targetKey: e.target.value
                }))
              }}>
              <option value="user_email" selected>아이디</option>
              <option value="user_name">성명</option>
              <option value="org_name">기관</option>
            </select>
            <input 
              id='searchInput'
              type='text'
              className='squareInput'
              placeholder=''
              value={searchState.keyword}
              style={{marginLeft: 8}}
              onChange={(e) => {
                setSearchState((prevState) => ({
                  ...prevState,
                  keyword: e.target.value
                }))
                searchAndUpdateDataWithTargetKey(e.target.value, searchState.targetKey!, rawUserData, setUserData, setPageState)
              }}/>
            <img src={searchState.keyword === '' ? `./src/ic_search.png` : `./src/ic_search_reset.png`} alt="reset button" style={{width: 20, height: 20, cursor: 'pointer', marginLeft: -30}}
              onClick={() => {
                if (searchState.keyword === '') return;
                setSearchState((prevState) => ({
                  ...prevState,
                  keyword: ''
                }));
                setUserData(rawUserData);
              }}/>

            <div className='flex gap-[32px]' style={{marginLeft: 'auto'}}>
              <button className='acceptBtn self-center' style={{width: 110}}
                onClick={() => {
                  if (checkedUsers.filter((v) => v.checked === true).length <= 0) {
                    openNotiDialog('선택된 회원이 없습니다.')
                    return;
                  }

                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    title: '선택된 가입 신청을 승인하시겠습니까?',
                    onAccept: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                      handleProcessSignupRequests(true);
                    },
                    onDeny: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                    }
                  }));
                }}>승인</button>
              <button className='outlineBtn self-center' style={{width: 110}}
                onClick={() => {
                  if (checkedUsers.filter((v) => v.checked === true).length <= 0) {
                    openNotiDialog('선택된 회원이 없습니다.')
                    return;
                  }

                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    title: '선택된 가입 신청을 거부하시겠습니까?',
                    onAccept: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                      handleProcessSignupRequests(false);
                    },
                    onDeny: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                    }
                  }));
                }}>거부</button>
            </div>




          </div>

          <div className='signupRequestsRow' style={{marginTop: 40, borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600}}>
            <div className='defaultCheckbox'>
              <input
                type='checkbox'
                name='cbAll'
                id={`cbAll`}
                onChange={(e) => {
                  onClickSelectAll(e.target.checked)
                }}
              />
              <label htmlFor={`cbAll`}></label>
            </div>
            <div>아이디</div>
            <div>성명</div>
            <div>소속기관</div>
            <div>사용자 유형</div>
            <div className='flexCenter'>
              <>가입 신청 시기</>
              <div style={{display: 'grid', gap: 10, marginLeft: 15}}>
                <img src={`./src/ic_arrow_top.png`} alt="오름차순 배열" style={{width: 12, cursor: 'pointer'}}/>
                <img src={`./src/ic_arrow_bottom.png`} alt="내림차순 배열" style={{width: 12, cursor: 'pointer'}}/>
              </div>
            </div>
          </div>




          <form>
          {
            userData?.slice(pageState.idx!*10+0, pageState.idx!*10+10).map((item, idx, arr) => {
              return (
                <div className='signupRequestsRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: (idx+1 === arr.length || (idx+1) %10 === 0) ? '1px solid #4F4F4F' : 'none'}}>
                  <div className='defaultCheckbox'>
                    <input
                      type='checkbox'
                      name='cb'
                      id={`cb${idx}`}
                      onChange={(e) => {
                        let users = cloneDeep(checkedUsers);
                        console.log(users);
                        users[idx].checked = e.target.checked;
                        setCheckedUsers(users);
                        updateSelectAll();
                      }}
                    />
                    <label htmlFor={`cb${idx}`}></label>
                  </div>
                  <div>{item.user_email}</div>
                  <div>{item.user_name}</div>
                  <div>{item.org_name}</div>
                  <select className={cn('userTypeSelect')} name="userType" id="userTypeSelect" 
                    value={checkedUsers[idx]?.role_id}
                    style={{marginLeft: 'auto', backgroundImage: 'url(./src/ic_arrow_bottom.png)'}}
                    onChange={(e) => {
                      let users = cloneDeep(checkedUsers);
                      console.log(users);
                      users[idx].role_id = Number(e.target.value);
                      setCheckedUsers(users);
                    }}>
                    {roleData.map((r, i) => {
                      return (
                        <option value={r.role_id}>{r.role_description}</option>
                      )
                    })}
                  </select>
                  <div>{dateToYMDHHMM(item.signup_at)}</div>
                </div>
              )}
            )
          }
          </form>

          <CommonPageNavi pageState={pageState} setPageState={setPageState}/>

        
        
        </div>
        

      </div>
    </>
  );
}