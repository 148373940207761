import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import * as API from '../../APIProvider'
import { useRecoilState } from 'recoil';
import { CohortStatus, DialogInfoState, Notice, RegionSeverityAppState, ShinyLoadingState, UserInfoState } from '../../interfaces';
import { dialogInfoState, userInfoState } from '../../globalState';
import { dateToYMD, dateToYMDHHMM, openNotiDialogWithSetter } from '../../util';
import axios from 'axios';

//const RSHINY_HOST = process.env.REACT_APP_RSHINY_HOST;
const RSHINY_HOST = 'https://prod.pharos.or.kr/rshiny';
//const APP_HOST = process.env.REACT_APP_HOST;
const APP_HOST = 'https://prod.pharos.or.kr/';

export default function GroupInsightPage() {

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [loadingState, setLoadingState] = useState<ShinyLoadingState>({
    app_cohort_info: false,
    app_cohort_disease: false,
    app_cohort_region_serverity: false,
    app_cohort_pcr: false
  });

  const [rsAppState, setRSAppState] = useState<RegionSeverityAppState>({ // region-serverity APP state
    mode: 'region',
    region: 'map'
  })

  const [cohortStatus, setCohortStauts] = useState<CohortStatus>({
    confirmed: {
      total: 0,
      added: 0
    },
    hospitalization: {
      total: 0,
      added: 0
    },
    discharge: {
      total: 0,
      added: 0
    },
    death: {
      total: 0,
      added: 0
    }
  })

  const handleLoadCohortStatus = () => {
    axios.get(`${APP_HOST}/cohort_status.json`)
    .then((res) => {
      setCohortStauts(JSON.parse(res.data));
    })
    .catch((err) => {
      //openNotiDialog('오류가 발생했습니다.');
    })
  };

  useEffect(() => {
    if (!userState.isLoaded) return;

    if (!userState.isLoggedIn) {
      navigate('/login');
      return;
    }
    else if (!(userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'clinician')) {
      navigate('/')
      return;
    }
    
    handleLoadCohortStatus();
  }, [userState.isLoaded])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', paddingBottom: 44}}>
        <div className='flexStart' style={{width: 1200, marginTop: 24, height: 45}}>
          <span className='inter800-20-20'>집단수준 감염통계</span>

          <select className='squareSelect inter600-16-20'
            value={""}
            style={{width: 315, backgroundPosition: 275, backgroundImage: 'url(./src/ic_select_arrow.png)', marginLeft: 'auto', color: '#616161', borderRadius: '21px'}}
            onChange={(e) => {
            }}>
            <option value="influenza" selected>Influenza</option>
          </select>
        </div>

        <div className='mainGrid' style={{marginTop: 20, gap: '32px 35px'}}>

          <div className='iframeCard' style={{width: 620, height: 394, background: 'white'}}>
            <div className='inter800-16-20' style={{padding: '22px 0px 0px 32px'}}>
              인구통계
            </div>
            <div className='inter700-12-20' style={{display: 'grid', gridTemplateColumns: '1fr 1.7fr', marginLeft: 32, marginTop: 10}}>
              <div>Sex Proportion</div>
              <div>Age Proportion</div>
            </div>
            <div style={{display: 'grid', height: 310, gridTemplateColumns: '1fr 1.5fr', gap: 10, marginLeft: -10}}>
              <iframe src={`${RSHINY_HOST}/sexProportion.html`} />
              <iframe src={`${RSHINY_HOST}/ageProportion.html`} />
            </div>
            
          </div>


          <div style={{width: 620, height: 147, position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
            <div style={{padding: '19px 24px', background: '#1740C5', color: 'white', borderRadius: '8px 0px 0px 8px'}}>
              <div className='inter800-18-20'>확진자</div>
              <div className='flexAlignEnd inter600-14-20' style={{gap: 6, marginTop: 10}}>
                <img src={`./src/ic_count_added.png`} alt="count added" style={{width: 20}}/>
                {cohortStatus?.confirmed.added}
              </div>
              <div className='inter700-32-20' style={{marginTop: 24}}>{cohortStatus?.confirmed.total}</div>
            </div>

            <div style={{padding: '19px 24px', background: '#026CE9', color: 'white'}}>
              <div className='inter800-18-20'>치료</div>
              <div className='flexAlignEnd inter600-14-20' style={{gap: 6, marginTop: 10}}>
                <img src={`./src/ic_count_added.png`} alt="count added" style={{width: 20}}/>
                {cohortStatus?.hospitalization.added}
              </div>
              <div className='inter700-32-20' style={{marginTop: 24}}>{cohortStatus?.hospitalization.total}</div>
            </div>

            <div style={{padding: '19px 24px', background: '#52B6ED', color: 'white'}}>
              <div className='inter800-18-20'>완치</div>
              <div className='flexAlignEnd inter600-14-20' style={{gap: 6, marginTop: 10}}>
                <img src={`./src/ic_count_added.png`} alt="count added" style={{width: 20}}/>
                {cohortStatus?.discharge.added}
              </div>
              <div className='inter700-32-20' style={{marginTop: 24}}>{cohortStatus?.discharge.total}</div>
            </div>

            <div style={{padding: '19px 24px', background: 'white', color: '#44ADDA', borderRadius: '0px 8px 8px 0px', border: '1px solid #52B6ED'}}>
              <div className='inter800-18-20'>사망</div>
              <div className='flexAlignEnd inter600-14-20' style={{gap: 6, marginTop: 10}}>
                <img src={`./src/ic_count_added_blue.png`} alt="count added" style={{width: 20}}/>
                {cohortStatus?.death.added}
              </div>
              <div className='inter700-32-20' style={{marginTop: 24}}>{cohortStatus?.death.total}</div>
            </div>
          </div>


          <div className='iframeCard' style={{width: 620, height: 394, background: 'white'}}>
            <div className='inter800-16-20' style={{padding: '22px 0px 0px 32px'}}>
              PCR
            </div>
            <div style={{display: 'grid', height: 330, gap: 10, marginTop: 10}}>
              <iframe src={`${RSHINY_HOST}/graphPCR.html`} />
            </div>
          </div>

          <div className='iframeCard' style={{width: 620, height: 644, background: 'white', marginTop: -245, position: 'relative'}}>
            <div className='inter800-16-20 flexStart' style={{padding: '16px 32px 0px 32px', alignItems: 'flex-start'}}>
              <div style={{marginTop: 10}}>Region & Severity</div>
              <div style={{marginLeft: 'auto'}}>
                <div className='tabButtonContainer inter700-12-20'>
                  <div className='tabButtonRow'>
                    <div className={rsAppState.mode === 'region' ? 'tabButtonSelected' : 'tabButton'} onClick={() => {
                      setRSAppState((prevState) => ({
                        ...prevState,
                        mode: 'region'
                      }))
                    }}>Region</div>
                    <div className={rsAppState.mode === 'severity' ? 'tabButtonSelected' : 'tabButton'} onClick={() => {
                      setRSAppState((prevState) => ({
                        ...prevState,
                        mode: 'severity'
                      }))
                    }}>Serverity</div>
                  </div>
                  {rsAppState.mode === 'region' ?
                    <div className='tabButtonRow inter700-10-26' style={{marginTop: 8, justifyContent: 'center'}}>
                      <div className={rsAppState.region === 'map' ? 'tabButtonSelected' : 'tabButton'} onClick={() => {
                        setRSAppState((prevState) => ({
                          ...prevState,
                          region: 'map'
                        }))
                      }}>Map</div>
                      <div className={rsAppState.region === 'graph' ? 'tabButtonSelected' : 'tabButton'} onClick={() => {
                        setRSAppState((prevState) => ({
                          ...prevState,
                          region: 'graph'
                        }))
                      }}>Regional Graph</div>
                    </div>
                  : null}
                </div>
                
              </div>
            </div>
            
            {rsAppState.mode === 'region' ?
            <div className='flexColumnsStart' style={{width: 600, height: 560, gap: 10}}>
              {rsAppState.region === 'map' ? // Region TAB - MAP
              <iframe className='mainIframe' src={`${RSHINY_HOST}/kMap.html`}/>
              : null}
              <iframe className='mainIframe' src={`${RSHINY_HOST}/app_cohort_region_serverity/`}
                style={{display: rsAppState.mode === 'region' && rsAppState.region === 'graph' ? 'grid' : 'none', transformOrigin: 'none'}}/>
            </div>
            : null}

            {rsAppState.mode === 'severity' ?
            <div className='inter700-12-20' style={{height: 560, gap: 8, overflowY: 'auto', display: 'grid', gridTemplateRows: '30px 250px 30px 250px 30px 250px 30px 250px', padding: '0px 24px'}}>
              <div style={{margin: '12px 0px 0px 12px'}}>Nasal cannula</div>
              <iframe src={`${RSHINY_HOST}/serverityPlots_1.html`}/>
              <div style={{margin: '12px 0px 0px 12px'}}>Non-invasive Oxygen therapy</div>
              <iframe src={`${RSHINY_HOST}/serverityPlots_2.html`}/>
              <div style={{margin: '12px 0px 0px 12px'}}>Invasive mechanical ventilation</div>
              <iframe src={`${RSHINY_HOST}/serverityPlots_3.html`}/>
              <div style={{margin: '12px 0px 0px 12px'}}>Extracorporeal membrane oxygenation</div>
              <iframe src={`${RSHINY_HOST}/serverityPlots_4.html`}/>
            </div>
            : null}

          </div>

        </div>
      </div>


    </>
  );
}