import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import * as API from '../../APIProvider'
import { dialogInfoState, userInfoState } from '../../globalState';
import { DialogInfoState, LoginState, UserInfoState } from '../../interfaces';
import { openNotiDialogWithSetter } from '../../util';


export default function LoginPage() {

  const navigate = useNavigate();

  // states
  const [loginState, setLoginState] = useState<LoginState>({
    email: '',
    password: ''
  })
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);


  const handleLogin = () => {
    API.login(loginState.email, loginState.password)
    .then((res) => {
      handleLoadMyInfo();
      navigate('/');
    })
    .catch((err) => {
      if (err.response.status === 400 && err.response.data.message) {
        if (err.response.data.message.includes('pending')) {
          openNotiDialog('가입 신청 대기중입니다.\n관리자 승인 후 로그인이 가능합니다.');
        }
        else if (err.response.data.message.includes('Invalid')) {
          openNotiDialog('아이디 또는 비밀번호가 올바르지 않습니다.');
        }
        else if (err.response.data.message.includes('withdrawn')) {
          openNotiDialog('탈퇴된 사용자입니다.');
        }
        else {
          openNotiDialog('오류가 발생했습니다.')
        }
      }
      else {
        openNotiDialog('오류가 발생했습니다.');
      }
    })
  }

  const handleLoadMyInfo = () => {
    API.getMyInfo()
    .then((res) => {
      setUserState((prevState) => ({
        ...prevState,
        isLoggedIn: true,
        email: res.data.user_email,
        name: res.data.user_name,
        phone: res.data.user_phone,
        role_name: res.data.role_name,
        org_name: res.data.org_name
      }))
    })
    .catch((err) => {
      setUserState({
        isLoaded: true,
        isLoggedIn: false
      })
      //openNotiDialog('오류가 발생했습니다.');
    })
  }

  return (
    <div className='bluePaper' style={{padding: '40px 0px'}}>
      <div className='grid grid-cols-2 bg-white rounded-[8px]' style={{width: 1260, height: 754}}>
        <img src={`./src/bg_login.png`} alt="login background"/>
        <div className='flexColumnsCenter'>
          <div className='inter400-27-33' style={{color: '#073CA4'}}>
            감염병 환자정보관리 통합 시스템
          </div>
          <div className='inter600-17-21' style={{color: '#067BE6', marginTop: 19}}>
            감염병 환자정보관리 통합 시스템
          </div>
          <input 
            id='idInput'
            type='text'
            placeholder='아이디 (e-mail)'
            className='loginInput'
            style={{
              marginTop: 46
            }}
            onChange={(e) => {
              setLoginState((prevState) => ({
                ...prevState,
                email: e.target.value
              }))
            }}
            >
          </input>
          <input 
            id='pwInput'
            type='password'
            placeholder='비밀번호  (password)'
            className='loginInput'
            style={{
              marginTop: 28
            }}
            onChange={(e) => {
              setLoginState((prevState) => ({
                ...prevState,
                password: e.target.value
              }))
            }}
            >
          </input>
          <button className='loginBtn' style={{marginTop: 80}}
            onClick={() => {
              handleLogin();
            }}>
            로그인
          </button>
          <div className='grid' style={{marginTop: 28, width: 280, gridTemplateColumns: '2fr 1px 2fr'}}>
            <div className='loginTextBtn' onClick={() => navigate('/signup')}>
              회원가입
            </div>
            <div style={{borderLeft: '1.5px solid black'}}/>
            <div className='loginTextBtn' style={{marginLeft: 20}} onClick={() => navigate('/forgot-password')}>
              비밀번호 찾기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}