export interface ISimpleTooltipProps {
  message: string
}

const SimpleTooltip = ({ message }: ISimpleTooltipProps) => {
  return (
    <div className="tooltipWrapper">
      <img className='icon' src={`./src/ic_info.svg`} />
      <div className='tooltipContent'>
        <img src={`./src/Tooltip-Tail.svg`} />
        <span>{message}</span>
      </div>
    </div>
  )
}

export default SimpleTooltip
