import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { DialogInfoState, NewNotice } from '../../interfaces';
import * as API from '../../APIProvider'
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { openNotiDialogWithSetter } from '../../util';

export default function NoticeNewPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [newNotice, setNewNotice] = useState<NewNotice>({
    title: '',
    content: ''
  });

  const handlePostNotice = () => {
    API.postNotice(newNotice.title, newNotice.content)
    .then((res) => {
      navigate('/notice')
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', background: 'white', paddingBottom: 50}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-18-20'>공지사항</span>
        </div>

        <div className='noticeContentTopRow' style={{marginTop: 40, gridTemplateColumns: '1fr'}}>
          <input 
            id='titleInput'
            type='text'
            placeholder='제목을 입력해 주세요.'
            className='noticeTitleInput'
            onChange={(e) => {
              setNewNotice((prevState) => ({
                ...prevState,
                title: e.target.value
              }))
            }}
            >
          </input>
        </div>
        <div className='noticeContentBox' style={{background: '#EDF5FA', padding: '27px 42px', borderBottom: '1px solid #4F4F4F'}}>
          <textarea 
            id='contentInput'
            placeholder='내용을 입력해 주세요.'
            className='noticeContentInput'
            onChange={(e) => {
              setNewNotice((prevState) => ({
                ...prevState,
                content: e.target.value
              }))
            }}
            >
          </textarea>
        </div>
        

        <div className='flexCenter' style={{marginTop: 61, gap: 25}}>
          <button className='acceptBtn' style={{width: 144}}
            onClick={() => {
              setDialogState((prevState) => ({
                ...prevState,
                isOpen: true,
                title: '공지를 등록하시겠습니까?',
                onAccept: () => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: false
                  }))
                  handlePostNotice();
                },
                onDeny: () => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: false
                  }))
                }
              }));
            }}>공지 등록</button>
          <button className='outlineBtn' style={{width: 110}}
            onClick={() => {
              navigate('/notice')
            }}>목록</button>
        </div>
        

      </div>
    </>
  );
}