import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState, userInfoState } from '../../globalState';
import { Cohort, DialogInfoState, Org, StatRequestInfo, UserInfoState } from '../../interfaces';
import { epochToYMDHHMM, openNotiDialogWithSetter } from '../../util';
import * as API from '../../APIProvider'
import { cloneDeep } from 'lodash';

export default function StatsCreatePage() {

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [selectDialogState, setSelectDialogState] = useState<DialogInfoState>({
    isOpen: false,
    title: '기관목록',
    desc: '요청할 기관을 선택해주세요.',
    onAccept: () => {
      setSelectDialogState((prevState) => ({
        ...prevState,
        isOpen: false
      }))
    },
    onDeny: undefined
  });

  const [orgData, setOrgData] = useState<Array<Org>>();
  const [cohortData, setCohortData] = useState<Array<Cohort>>();
  const [statRequestInfo, setStatRequestInfo] = useState<StatRequestInfo>({
    recipient_org: new Set(),
    cohort_id: 0,
    request_comment: ''
  });

  const handleLoadOrg = () => {
    API.getAllHospital()
    .then((res) => {
      setOrgData(res.data);
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleLoadCohort = () => {
    API.getAllCohorts()
    .then((res) => {
      setCohortData(res.data);
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleSubmitRequest = () => {
    API.postStatsRequest(
      Array.from(statRequestInfo.recipient_org).map(v => v.id),
      statRequestInfo.cohort_id,
      statRequestInfo.request_comment
    )
    .then((res) => {
      setDialogState((prevState) => ({
      ...prevState,
      isOpen: true,
      title: '통계 요청을 전송했습니다.',
      onAccept: () => {
        setDialogState((prevState) => ({
          ...prevState,
          isOpen: false
        }))
        navigate('/stats/result');
      },
      onDeny: undefined
    }));

    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  useEffect(() => {
    if (!userState.isLoaded) return;

    if (!userState.isLoggedIn) {
      navigate('/login');
      return;
    }
    else if (!(userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' || userState.role_name === 'clinical_researcher' || userState.role_name === 'public_health_expert')) {
      navigate('/')
      return;
    }

    handleLoadCohort();
  }, [userState.isLoaded])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', paddingBottom: 50}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-20-20'>통계 요청 생성</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '19px 49px'}}>
          <div className='flexStart inter800-16-20' style={{width: 1174, marginTop: 10}}>
           
            <>요청 기관</>

            {/* <select className={cn('searchTypeSelect')} name="searchType" id="searchTypeSelect" 
              value={""}
              style={{marginLeft: 'auto', backgroundImage: 'url(./src/ic_select_arrow.png)'}}
              onChange={(e) => {
              }}>
              <option value="" disabled selected>아이디</option>
              <option value="병원">병원</option>
            </select>

            <input 
              id='searchInput'
              type='text'
              className='squareInput'
              placeholder=''
              style={{marginLeft: 8}}
              onChange={(e) => {
              }}
              />
            <img src={`./src/ic_search.png`} alt="seacrh button" style={{width: 20, height: 20, cursor: 'pointer', marginLeft: -30}}/> */}
          
          </div>

          <div className='grayBox grid grid-cols-7' style={{height: 113, marginTop: 17}}>
            {Array.from(statRequestInfo.recipient_org).map((item, idx) => {
              return (
                <div className='hospitalBtn'>
                  <>{item.name}</>
                  <img src={`./src/ic_close.png`} style={{width: 20, marginLeft: 'auto', cursor: 'pointer'}} 
                    onClick={() => {
                      let orgs = statRequestInfo.recipient_org;
                      orgs.delete(item);
                      setStatRequestInfo((prevState) => ({
                        ...prevState,
                        recipient_org: orgs
                      }))
                    }}/>
                </div>
              )
            })}
            <img src={`./src/btn_add.png`} style={{width: 36, cursor: 'pointer', marginTop: 4, marginLeft: 4}} alt="기관 추가"
              onClick={() => {
                handleLoadOrg();
                setSelectDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true
                }))
              }}/>
          </div>

          <div className='flexStart inter800-16-20' style={{width: 1174, marginTop: 24}}>
            코호트
          </div>

          <div className='cohortRow' style={{marginTop: 19, borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600}}>
            <div></div>
            <div>아이디</div>
            <div>이름</div>
            <div>생성일</div>
          </div>

          <form>
            {cohortData
            ?
            cohortData.map((item, idx) => {
              return (
                <div className='cohortRow' style={{background: idx%2 === 0 ? '#F2F2F2' : 'none', borderBottom: idx+1 === cohortData.length ? '1px solid #4F4F4F' : 'none'}}>
                  <div className='cohortRadio'>
                    <input
                      type='radio'
                      name='cb'
                      id={`cbCohort${idx}`}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStatRequestInfo((prevState) => ({
                            ...prevState,
                            cohort_id: item.id
                          }))
                        }
                      }}
                    />
                    <label htmlFor={`cbCohort${idx}`}></label>
                  </div>
                  <div>{item.id}</div>
                  <div>{item.name}</div>
                  <div>{epochToYMDHHMM(item.createdDate)}</div>
                </div>
              )
            })
            : 
            <div style={{marginTop: 10, marginBottom: 10, borderBottom: '1px solid #4F4F4F'}}>
              코호트 로딩중..
            </div>
            
            }
            
          </form>

          <div className='flexStart inter800-16-20' style={{width: 1174, marginTop: 24}}>
            코멘트
          </div>
          <div className='grayBox' style={{height: 146, marginTop: 17}}>
            <textarea 
              id='contentInput'
              placeholder='내용을 입력해 주세요.'
              className='noticeContentInput'
              onChange={(e) => {
                setStatRequestInfo((prevState) => ({
                  ...prevState,
                  request_comment: e.target.value
                }))
              }}
              >
            </textarea>
          </div>


          <button className='acceptBtn' style={{width: 125, marginTop: 28}}
            onClick={() => {
              handleSubmitRequest();
            }}>요청</button>

        </div>



        {/* 기관 선택 dialog */}
        {selectDialogState.isOpen ?

        <div className='dialogBase'>
          <div className='dialogPaper' style={{width: 460, maxHeight: 439}}>
            <button className='self-end' style={{width: 20}}
              onClick={() => {
                setSelectDialogState((prevState) => ({
                  ...prevState,
                  isOpen: false
                }));
              }}>
              <img src={`./src/ic_close.png`} alt="닫기" />
            </button>

            <div className='flexColumnsStart' style={{marginTop: 3}}>
              <div className='inter600-16-20 text-center whitespace-pre-wrap'>
                {selectDialogState.title}
              </div>
              <div className='inter400-14-20 text-center whitespace-pre-wrap' style={{marginTop: 5, marginBottom: 31}}>
                {selectDialogState.desc}
              </div>

              <div style={{maxHeight: 218, overflowY: 'scroll', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
              {orgData?.map((item, idx, arr) => {
                return (
                  <div className='orgSelectRow' style={{borderBottom: arr.length-1 === idx ? 'none' : ''}} key={`org${idx}`}>
                    <div className='circleCheckbox'>
                      <input
                        type='checkbox'
                        name='cb'
                        id={`cb${idx}`}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setStatRequestInfo((prevState) => ({
                              ...prevState,
                              recipient_org: new Set(prevState.recipient_org).add(item)
                            }))
                          }
                          else {
                            let orgs = statRequestInfo.recipient_org;
                            orgs.delete(item);
                            setStatRequestInfo((prevState) => ({
                              ...prevState,
                              recipient_org: orgs
                            }))
                          }
                        }}
                      />
                      <label htmlFor={`cb${idx}`}></label>
                    </div>
                    <>{item.name}</>

                  </div>
                )
              })}
              </div>
              


              <div className='flex gap-[18px]' style={{marginTop: 31, marginBottom: 33}}>
                <button className='acceptBtn self-center' style={{width: 110}}
                  onClick={() => {
                    selectDialogState.onAccept();
                  }}>확인</button>
              </div>
            </div>

          </div>

        </div> 
        : null }
        

      </div>
    </>
  );
}