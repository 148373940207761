import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState, userInfoState } from '../../globalState';
import { DBMetricData, DialogInfoState, Org, PageState, PopupInfoState, StatRequestItem, UserInfoState } from '../../interfaces';
import * as API from '../../APIProvider'
import { dateToYMDHHMM, isEmptyObject, openNotiDialogWithSetter } from '../../util';
import CommonPageNavi from '../../components/CommonPageNavi';
import { CloudWatchClient, GetDashboardCommand, GetMetricStatisticsCommand } from "@aws-sdk/client-cloudwatch"; // ES Modules import
import { Chart, registerables, Tooltip } from 'chart.js'
import { Line } from 'react-chartjs-2'
import type { ChartData, ChartOptions } from 'chart.js';
import { sortBy } from 'lodash';
import moment from 'moment';

Chart.register(...registerables);
Chart.register([Tooltip])

const coloredLineOption = (str:string) => {

  let colorCode:string;
  switch (str) {
    case 'green':
      colorCode = '#35B779';
      break;
    case 'blue':
      colorCode = '#067BE6';
      break;
    case 'red':
      colorCode = '#FB4207';
      break;
    default:
      colorCode = '#35B779';
      break;
  }


  return ({
    elements: {
      line: {
        borderWidth: 1.2,
        borderColor: colorCode
      },
      point: {
        borderColor: colorCode,
        backgroundColor: colorCode,
        radius: 2,
        hoverRadius: 5
      }
      
    },
    plugins:{
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        display: false
      },
      y: {
        display: true,
        border: {
          display: false
        },
        ticks: {
          font: {
            size: 9
          }
        }
      }
    },
    responsive: false
  })
}



export default function DBInfoPage() {
  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [popupState, setPopupState] = useState<PopupInfoState>({
    isOpen: false,
    itemIdx: 0,
    type: 'request'
  });

  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: 'label',
        data: []
      }
    ]
  });
  const [metricData, setMetricData] = useState<DBMetricData>({
    CPUUtilization: {},
    DatabaseConnections: {},
    DBLoad: {},
    DBLoadCPU: {},
    DBLoadNonCPU: {},
    FreeStorageSpace: {},
    FreeableMemory: {}
  });

  const LoadWatch = () => {
    API.getDBMonitoringInfo()
    .then((res) => {
      setMetricData(res.data)
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }
  

  
  useEffect(() => {
    if (!userState.isLoaded) return;

    if (!userState.isLoggedIn) {
      navigate('/login');
      return;
    }
    else if (!(userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager')) {
      navigate('/')
      return;
    }

    LoadWatch();

  }, [userState.isLoaded]);


  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 24, height: 45}}>
          <span className='inter800-20-20'>DB 정보</span>
          <select className='squareSelect inter600-16-20'
            value={""}
            style={{width: 315, backgroundPosition: 275, backgroundImage: 'url(./src/ic_select_arrow.png)', marginLeft: 'auto', color: '#616161', borderRadius: '21px', marginTop: 0}}
            onChange={(e) => {
            }}>
            <option value="dev" selected>PHAROS 운영 DB</option>
          </select>
        </div>

        <div className='mainWideArea' style={{marginTop: 28, height: 'auto', padding: '0px'}}>
          
          <div className='flex' style={{gap: 18}}>
            <div className='card' style={{width: 642, height: 254}}>
              <div className='inter800-16-20'>CPU Utilization (%)</div>
              {!isEmptyObject(metricData.CPUUtilization) ?
              <Line
                datasetIdKey='id'
                data={metricData.CPUUtilization}
                style={{marginTop: 10, marginLeft: -15}}
                width="605" height="185" key="1"
                options={coloredLineOption('blue')}/>

                : null} 
            </div>

            <div className='card' style={{width: 642, height: 254}}>
              <div className='inter800-16-20'>Database Connections</div>
              {!isEmptyObject(metricData.DatabaseConnections) ?
              <Line
                datasetIdKey='id'
                data={metricData.DatabaseConnections}
                style={{marginTop: 10, marginLeft: -15}}
                width="605" height="185" key="1"
                options={coloredLineOption('blue')}/>
                : null} 
            </div>
          </div>

          <div className='flex' style={{gap: 18, marginTop: 20}}>
            <div className='card' style={{width: 422, height: 254}}>
              <div className='inter800-16-20'>DB Load</div>
              {!isEmptyObject(metricData.DBLoad) ?
              <Line
                datasetIdKey='id'
                data={metricData.DBLoad}
                style={{marginTop: 10, marginLeft: -15}}
                width="375" height="185" key="1"
                options={coloredLineOption('red')}/>
                : null} 
            </div>

            <div className='card' style={{width: 422, height: 254}}>
              <div className='inter800-16-20'>DB Load CPU</div>
              {!isEmptyObject(metricData.DBLoadCPU) ?
              <Line
                datasetIdKey='id'
                data={metricData.DBLoadCPU}
                style={{marginTop: 10, marginLeft: -15}}
                width="375" height="185" key="1"
                options={coloredLineOption('red')}/>
                : null} 
            </div>

            <div className='card' style={{width: 422, height: 254}}>
              <div className='inter800-16-20'>DB Load NonCPU</div>
              {!isEmptyObject(metricData.DBLoadNonCPU) ?
              <Line
                datasetIdKey='id'
                data={metricData.DBLoadNonCPU}
                style={{marginTop: 10, marginLeft: -15}}
                width="375" height="185" key="1"
                options={coloredLineOption('red')}/>
                : null} 
            </div>
          </div>

          <div className='flex' style={{gap: 18, marginTop: 20}}>
            <div className='card' style={{width: 642, height: 254}}>
              <div className='inter800-16-20'>Free Storage Space (GB)</div>
              {!isEmptyObject(metricData.FreeStorageSpace) ?
              <Line
                datasetIdKey='id'
                data={metricData.FreeStorageSpace}
                style={{marginTop: 10, marginLeft: -15}}
                width="605" height="185" key="1"
                options={coloredLineOption('green')}/>
                : null} 
            </div>

            <div className='card' style={{width: 642, height: 254}}>
              <div className='inter800-16-20'>Freeable Memory (GB)</div>
              {!isEmptyObject(metricData.FreeableMemory) ?
              <Line
                datasetIdKey='id'
                data={metricData.FreeableMemory}
                style={{marginTop: 10, marginLeft: -15}}
                width="605" height="185" key="1"
                options={coloredLineOption('green')}/>
                : null} 
            </div>
          </div>
          


        </div>

        

      </div>
    </>
  );
}