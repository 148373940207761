import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { DialogInfoState, FindPasswordInfo, FindPasswordState, PageState } from '../../interfaces';
import { openNotiDialogWithSetter, validateEmail } from '../../util';
import * as API from '../../APIProvider'


export default function ForgotPasswordPage() {

  const navigate = useNavigate();
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  // states
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const [isStepCleared, setIsStepCleared] = useState<boolean>(false);

  const [pageState, setPageState] = useState<PageState>({
    page: 'info'
  })

  const [findPasswordInfo, setFindPasswordInfo] = useState<FindPasswordInfo>({
    name: '',
    email: ''
  })
  const [findPasswordState, setFindPasswordState] = useState<FindPasswordState>({
    isEmailValidated: false
  })

  // API
  const handleRequestPasswordMail = () => {
    API.requestPasswordResetMail(findPasswordInfo.name, findPasswordInfo.email)
    .then((res) => {
      setPageState((prevState) => ({
        ...prevState,
        page: 'finish'
      }))

    })
    .catch((err) => {
      if (err.response.status === 400) {
        openNotiDialog('일치하는 정보가 없습니다.');
      }
      else {
        openNotiDialog('오류가 발생했습니다.');
      }
    })
  }

  useEffect(() => {
    setIsStepCleared(
      findPasswordInfo.email !== '' &&
      findPasswordInfo.name !== '' &&
      findPasswordState.isEmailValidated
    )
  }, [findPasswordInfo])

  return (
    <div className='bluePaper' style={{justifyContent: 'center'}}>

      {pageState.page === "info" ?
      <div className='mainCard' style={{justifyContent: 'center', height: 'auto'}}>
        <div className='inter600-22-27 text-center' style={{color: '#004EA1', width: 836}}>
          본인 확인
        </div>

        <div className='inter500-22-27 text-center' style={{color: '#8B9BB4', width: 836, marginTop: 27}}>
          아래의 정보를 입력해주십시오
        </div>

        <input 
            id='nameInput'
            type='text'
            placeholder='성명'
            className='loginInput'
            style={{
              marginTop: 42,
              width: 416
            }}
            onChange={(e) => {
              setFindPasswordInfo((prevState) => ({
                ...prevState,
                name: e.target.value
              }))
            }}
            >
          </input>
          <input 
            id='idInput'
            type='text'
            placeholder='아이디 (e-mail)'
            className='loginInput'
            style={{
              marginTop: 28,
              width: 416
            }}
            onChange={(e) => {
              setFindPasswordInfo((prevState) => ({
                ...prevState,
                email: e.target.value
              }))
              setFindPasswordState((prevState) => ({
                ...prevState,
                isEmailValidated: validateEmail(e.target.value)
              }))
            }}
            >
          </input>
        

        <div className='flex' style={{gap: 18, marginTop: 56}}>
          <button className='outlineBtn'
            onClick={() => {
              navigate('/login');
            }}>취소</button>
          <button className='acceptBtn' style={{opacity: isStepCleared ? 1 : 0.3, cursor: isStepCleared ? 'pointer' : 'default'}}
            onClick={() => {
              handleRequestPasswordMail();
            }}>다음</button>
        </div>
          
      </div> : null}

      {pageState.page === "finish" ?
      <div className='mainCard' style={{justifyContent: 'center', height: 'auto'}}>
        <div className='inter600-22-27 text-center' style={{color: '#004EA1', width: 836}}>
          비밀번호 재설정
        </div>

        <div style={{marginTop: 46, borderTop: '2px solid #004EA1', width: 413}}/>

        <div className='inter400-24-50 text-center' style={{marginTop: 35}}>
          입력하신 메일 주소로 비밀번호 재설정 링크가 발송되었습니다.<br/>
          메일이 보이지 않는다면, 관리자에게 문의하십시오.<br/>
        </div>

        <button className='acceptBtn' style={{width: 276, marginTop: 56}} onClick={() => navigate('/') }>처음으로</button>



          
      </div> : null}

    </div>
  );
}