import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState, PageState, UserInfoState } from '../interfaces';
import { dialogInfoState, userInfoState } from '../globalState';
import * as API from '../APIProvider'
import { openNotiDialogWithSetter } from '../util';
import axios from 'axios';

const ATLAS_HOST = process.env.REACT_APP_ATLAS_HOST;


export default function MainHeader() {
  
  const navigate = useNavigate();
  const location = useLocation();
  
  const [isStatBtnHover, setIsStatBtnHover] = useState<boolean>(false);
  const [isSystemBtnHover, setIsSystemBtnHover] = useState<boolean>(false);
  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  axios.interceptors.request.clear();
  axios.interceptors.response.clear();

  let isRefreshing = false;

  axios.interceptors.request.use(
    function (config) {
      config.headers!.Authorization = `Bearer ${localStorage.accessToken}`;
      return config;
    });
  
  axios.interceptors.response.use(
    function (res) {
      return res;
    },
    async function (err) {
      const deniedRequest = err.config;
  
      console.log(err);
      if (deniedRequest.url.includes('refresh')) return;

      if (err.response.status === 401 && err.response.data.message.includes('expired')) { // 토큰 만료
        
        await API.refreshToken()
        .then((res) => {
          
          if (res.status === 202) return;
          console.log(res)
          deniedRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
        })
        .catch((err) => {
          console.log(err)
          return Promise.reject(err);
        })
  
        const originRes = await axios.request(deniedRequest);
        return originRes;
      }
      else if (err.response.status === 401 && !err.response.data.message.includes('expired')) {
        setUserState({
          isLoaded: true,
          isLoggedIn: false
        })
        if (!location.pathname.includes('reset-password')) {
          navigate('/login')
        }
        return Promise.reject(err);
      }
      else {
        return Promise.reject(err);
      }
    }
  )

  const handleLoadMyInfo = () => {
    API.getMyInfo()
    .then((res) => {
      console.log(res);
      setUserState((prevState) => ({
        ...prevState,
        isLoaded: true,
        isLoggedIn: true,
        email: res.data.user_email,
        name: res.data.user_name,
        phone: res.data.user_phone,
        role_name: res.data.role_name,
        org_name: res.data.org_name
      }))
    })
    .catch((err) => {
      setUserState({
        isLoaded: true,
        isLoggedIn: false
      })
      if (!location.pathname.includes('reset-password')) {
        navigate('/')
      }
      //openNotiDialog('오류가 발생했습니다.');
    })
  }

  const handleLogout = () => {
    API.logout()
    .then((res) => {
      setUserState({
        isLoaded: true,
        isLoggedIn: false
      })
      navigate('/');
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  useEffect(() => {
    handleLoadMyInfo();
  }, [])

  return (
  <header className='flexColumnsCenter' style={{borderBottom: '1px solid #DCE5F3'}}>
    {/* 최상단 헤더 - 로그인 계정 정보 표시 */}
    <div className='flexCenter' style={{background: '#314B9E', width: '100%', height: 52, zIndex: 100}}
      onMouseEnter={() => {
        setIsStatBtnHover(false);
        setIsSystemBtnHover(false);
      }}>
      <div className='flexEnd contentFullWidth'>
        {userState.isLoggedIn
        ?
        <div className='flexCenter' style={{cursor: 'pointer'}} onClick={() => { setIsDropdownOpen((prevState) => (!prevState)); }}>
          <img src={`./src/avatar.png`} style={{width: 28, height: 28, marginRight: 6}}/>
          <div className='inter400-12-15 text-white'>
            {userState.email}
          </div>
          <img src={isDropdownOpen ? `./src/ic_arrow_top_white.png` : `./src/ic_arrow_bottom_white.png`} style={{width: 22, marginLeft: 8, marginRight: 31}}/>
        </div>
        :
        userState.isLoaded
        ?
        <button className='linkToLoginBtn' onClick={() => {
          navigate('/login')
        }}/>
        : <div className='circularProgress' />
      }


      </div>
      
    </div>

    {isDropdownOpen ? 
    <div className='dialogBase' style={{width: '100%', minWidth: 'var(--content-full-width)', position: 'absolute', alignItems: 'start', background: 'none', boxShadow: 'none'}}
      onClick={() => {setIsDropdownOpen(false)}}>
      <div className='contentFullWidth' style={{minWidth: 'var(--content-full-width)', zIndex: 105, marginTop: -24}} onClick={() => {setIsDropdownOpen(false);}}>
        <div className='userDropdown'>
          <button className='userDropdownBtn' onClick={() => {
              navigate('/notice');
              setIsDropdownOpen(false);
            }}>
            공지사항
          </button>
          <button className='userDropdownBtn' onClick={() => {
              navigate('/my');
              setIsDropdownOpen(false);
            }}>
            마이페이지
          </button>
          <button className='userDropdownBtn' onClick={() => {
              handleLogout();
              setIsDropdownOpen(false);
            }}>
            로그아웃
          </button>
        </div> 
      </div>
    </div>
    : null}

    {/* 상단 헤더 - 메뉴 바*/}
    <div className='contentFullWidth' style={{background: 'white', height: 'auto', zIndex: 100, display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
      <img src={`./src/logo.png`} style={{width: 144, cursor: 'pointer', marginLeft: 24, alignSelf: 'center'}} onClick={() => navigate('/')} />
      <div className='flexStart ml-auto' style={{height: '90px'}}>

        {/* <button className='headerBtn' onClick={() => { navigate('/') }}>
          Home
        </button> */}

        {userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'clinician' ?
        <button className='headerBtn' onClick={() => {
          navigate('/insight/group')
        }}>
          집단수준 감염통계
        </button>
        : null}

        {userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' || userState.role_name === 'clinical_researcher' || userState.role_name === 'clinician' ?
        <button className='headerBtn' onClick={() => { navigate('/insight/individual') }}>
          감염환자 임상정보
        </button>
        : null}

        {userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' ?
        <button className='headerBtn' onClick={() => { navigate('/db') }}
          onMouseEnter={() => {
            setIsSystemBtnHover(false);
            setIsStatBtnHover(false);
          }}>
          DB 정보
        </button>
        : null}

        {userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' || userState.role_name === 'clinical_researcher' || userState.role_name === 'public_health_expert' ?
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content'}}>
          <button className='headerBtn' onClick={() => {}}
            onMouseEnter={() => {
              setIsSystemBtnHover(false);
              setIsStatBtnHover(true);
            }}
            onMouseLeave={() => {
              //setIsStatBtnHover(false)
            }}>
            통계 요청
          </button>

          {isStatBtnHover &&
          <div style={{position: 'absolute', top: 143, paddingTop: '20px'}}
            onMouseLeave={() => {
              // setIsStatBtnHover(false)
            }}>

            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/stats/create');
                setIsStatBtnHover(false);
              }}>통계 요청 생성</div>

            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/stats/result');
                setIsStatBtnHover(false);
              }}>통계 요청 결과</div>
            
            {userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' ?
            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/stats/response');
                setIsStatBtnHover(false);

              }}>통계 요청 처리</div>
            : null}



          </div>}

          {isStatBtnHover &&
          <div style={{position: 'absolute', width: '100%', 
              height: userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' ? 172 : 128,  
              background: 'white', left: 0, top: 143, zIndex: -1, borderBottom: '1px solid #C4D0E1'}} 
            onMouseLeave={() => {
              setIsStatBtnHover(false)
            }}/>
          }

        </div>

        : null}

        {userState.role_name === 'system_admin' || userState.role_name === 'org_admin'?
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <button className='headerBtn'
            onMouseEnter={() => {
              setIsStatBtnHover(false);
              setIsSystemBtnHover(true);
            }}
            onMouseLeave={() => {
              //setIsSystemBtnHover(false)
            }}>
            시스템 관리
          </button>

          {isSystemBtnHover &&
          <div style={{position: 'absolute', top: 143, paddingTop: '20px'}}
            onMouseLeave={() => {
              // setIsSystemBtnHover(false)
            }}>
            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/admin/users');
                setIsSystemBtnHover(false);
              }}>회원 목록</div>
            {userState.role_name === 'system_admin' && 
            <>
            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/admin/signup-requests');
                setIsSystemBtnHover(false);
              }}>가입 신청 목록</div>
            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/admin/stats');
                setIsSystemBtnHover(false);
              }}>통계 요청 내역</div>
            <div className='headerRibbonBtn'
              onClick={() => {
                navigate('/admin/trendtime');
                setIsSystemBtnHover(false);
              }}>감염병 상태 변경</div>
            <div className='headerRibbonBtn' 
              onClick={() => {
                navigate('/admin/notification');
                setIsSystemBtnHover(false);
              }}>메일 알림 설정</div>
            </> }
          </div>}

          {isSystemBtnHover &&
          <div style={{position: 'absolute', width: '100%', 
            height: userState.role_name === 'system_admin' ? 250 : 84,
            background: 'white', left: 0, top: 143, zIndex: -1, borderBottom: '1px solid #C4D0E1'}} 
            onMouseLeave={() => {
              setIsSystemBtnHover(false)
            }}
            />
            
          }

        </div>
        : null}

        {userState.isLoggedIn && <button className='headerBtn'
          onMouseEnter={() => {
            setIsSystemBtnHover(false);
            setIsStatBtnHover(false);
          }}
          onClick={() => {
            window.open(ATLAS_HOST, '_blank');
          }}
          >
          ATLAS 열기
        </button>}
      </div>
    </div>

    {/* {isStatBtnHover || isSystemBtnHover ?
    <div 
      className='headerRibbonBg'
      onClick={() => {
        setIsStatBtnHover(false);
        setIsSystemBtnHover(false);
      }}>
    </div>

    : null} */}

  </header>
  );
}