import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TERM } from '../../strings';
import { useRecoilState } from 'recoil';
import { DialogInfoState, UserInfoState } from '../../interfaces';
import { dialogInfoState, userInfoState } from '../../globalState';
import { openNotiDialogWithSetter, validatePassword } from '../../util';
import * as API from '../../APIProvider'

interface PageState {
  page: string;
}

export default function ResetPasswordPage() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [pageState, setPageState] = useState<PageState>({
    page: 'info'
  })
  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const [passwordInfo, setPasswordInfo] = useState<{
    password: string
    password_confirm: string
  }>({
    password: '',
    password_confirm: ''
  })
  const [passwordState, setPasswordState] = useState<{
    isPasswordValidated: boolean
    isPasswordMatched: boolean
  }>({
    isPasswordValidated: false,
    isPasswordMatched: false
  })

  const handleSubmitPassword = () => {
    if (!passwordState.isPasswordValidated || !passwordState.isPasswordMatched) {
      openNotiDialog("비밀번호를 다시 확인해주세요.")
      return;
    }


    console.log(searchParams.get('token'))

    API.resetPassword(passwordInfo.password, searchParams.get('token')!)
    .then(() => {
      setDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        title: '비밀번호 재설정을 완료하였습니다.\n메인화면으로 이동합니다.',
        onAccept: () => {
          setDialogState((prevState) => ({
            ...prevState,
            isOpen: false,
            onAccept: () => {
              setDialogState((prevState) => ({
                ...prevState,
                isOpen: false
              }))
            }
          }))
          navigate('/')
        }
      }));
    })
    .catch(err => {
      console.log(err)
      openNotiDialog('오류가 발생했습니다.');
    })
  }


  useEffect(() => {
    if ((userState.isLoaded && userState.isLoggedIn) || !searchParams.get('token')) {
      navigate('/')
    }
  }, [userState.isLoaded])

  return (
    <div className='bluePaper' style={{justifyContent: 'center'}}>

      {pageState.page === "info" ?
      <div className='mainCard' style={{justifyContent: 'center', height: 'auto', paddingRight: 100}}>
        <div className='inter600-22-27 text-center' style={{color: '#004EA1', width: 836}}>
          비밀번호 재설정
        </div>

        <div className='passwordResetInfoRow' style={{marginTop: 52}}>
          <div className='text-right'>새 비밀번호</div>
          <input 
            id='pwInput'
            type='password'
            className='loginInput'
            style={{width: 416}}
            onChange={(e) => {
              setPasswordInfo((prevState) => ({
                ...prevState,
                password: e.target.value
              }));
              setPasswordState((prevState) => ({
                ...prevState,
                isPasswordValidated: validatePassword(e.target.value),
                isPasswordMatched: passwordInfo.password_confirm === e.target.value
              }))
            }}
            >
          </input>
        </div>
        <div className='passwordResetInfoRow' style={{gridTemplateColumns: '130px 1fr 20px'}}>
          <div />
          <div>영문 + 숫자 + 특수문자 10자 이상</div>
          <div style={{width: 24, height: 24}}>
            <img src={passwordState.isPasswordValidated ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
          </div>
        </div>

        <div className='passwordResetInfoRow' style={{marginTop: 10}}>
          <div className='text-right'>새 비밀번호 확인</div>
          <input 
            id='pwConfirmInput'
            type='password'
            className='loginInput'
            style={{width: 416}}
            onChange={(e) => {
              setPasswordInfo((prevState) => ({
                ...prevState,
                password_confirm: e.target.value
              }));
              setPasswordState((prevState) => ({
                ...prevState,
                isPasswordMatched: passwordInfo.password === e.target.value
              }));
            }}
            >
          </input>
        </div>
        <div className='passwordResetInfoRow' style={{gridTemplateColumns: '130px 1fr 20px'}}>
          <div />
          <div>비밀번호 일치</div>
          <div style={{width: 24, height: 24}}>
            <img src={passwordState.isPasswordMatched ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
          </div>
        </div> 


        

        <div className='flex' style={{gap: 18, marginTop: 45}}>
          <button className='acceptBtn' style={{width: 148}}
            onClick={() => {
              handleSubmitPassword()
            }}>완료</button>
        </div>
          
      </div> : null}

      {pageState.page === "finish" ?
      <div className='mainCard' style={{justifyContent: 'center', height: 'auto'}}>
        <div className='inter600-22-27 text-center' style={{color: '#004EA1', width: 836}}>
          비밀번호 재설정
        </div>

        <div style={{marginTop: 46, borderTop: '2px solid #004EA1', width: 413}}/>

        <div className='inter400-24-50 text-center' style={{marginTop: 35}}>
          비밀번호 재설정이 완료되었습니다.<br/>
          다시 로그인해주십시오.<br/>
        </div>

        <button className='acceptBtn' style={{width: 276, marginTop: 56}} onClick={() => navigate('/') }>처음으로</button>



          
      </div> : null}

    </div>
  );
}