import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogInfoState, NewNotice, Notice } from '../../interfaces';
import * as API from '../../APIProvider'
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { openNotiDialogWithSetter } from '../../util';
import { isNumber } from 'lodash';

export default function NoticeEditPage() {

  const navigate = useNavigate();
  const location = useLocation();

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [notice, setNotice] = useState<Notice>();

  const [newNotice, setNewNotice] = useState<NewNotice>({
    title: '',
    content: ''
  });

  const handleUpdateNotice = (id: number) => {
    API.updateNotice(id, newNotice.title, newNotice.content)
    .then((res) => {
      navigate('/notice')
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  const handleLoadNotice = (id: number) => {
    API.getNotice(id)
    .then((res) => {
      console.log(res)
      setNotice(res.data[0]);
      setNewNotice({
        title: res.data[0].title,
        content: res.data[0].content
      })
    })
  }

  useEffect(() => {
    if (!isNumber(Number(location.pathname.split('/').pop()))) navigate('/notice')
    handleLoadNotice(Number(location.pathname.split('/').pop()))
  }, [location.pathname])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', background: 'white', paddingBottom: 50}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-18-20'>공지사항 수정</span>
        </div>

        <div className='noticeContentTopRow' style={{marginTop: 40}}>
          <input 
            id='titleInput'
            type='text'
            placeholder='제목을 입력해 주세요.'
            value={newNotice.title}
            className='noticeTitleInput'
            onChange={(e) => {
              setNewNotice((prevState) => ({
                ...prevState,
                title: e.target.value
              }))
            }}
            >
          </input>
        </div>
        <div className='noticeContentBox' style={{background: '#EDF5FA', padding: '27px 42px', borderBottom: '1px solid #4F4F4F'}}>
          <textarea 
            id='contentInput'
            placeholder='내용을 입력해 주세요.'
            value={newNotice.content}
            className='noticeContentInput'
            onChange={(e) => {
              setNewNotice((prevState) => ({
                ...prevState,
                content: e.target.value
              }))
            }}
            >
          </textarea>
        </div>
        

        <div className='flexCenter' style={{marginTop: 61, gap: 25}}>
          <button className='acceptBtn' style={{width: 144}}
            onClick={() => {
              setDialogState((prevState) => ({
                ...prevState,
                isOpen: true,
                title: '공지를 업데이트하시겠습니까?',
                onAccept: () => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: false
                  }))
                  handleUpdateNotice(notice!.id);
                },
                onDeny: () => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: false
                  }))
                }
              }));
            }}>공지 등록</button>
          <button className='outlineBtn' style={{width: 110}}
            onClick={() => {
              navigate('/notice')
            }}>취소</button>
        </div>
        

      </div>
    </>
  );
}