import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState, UserInfoState } from '../../interfaces';
import { dialogInfoState, userInfoState } from '../../globalState';
import { openNotiDialogWithSetter, validatePassword } from '../../util';
import * as API from '../../APIProvider'
import { isNumber } from 'lodash';


export default function MyInfoPage() {

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [isOpenPhoneDialog, setIsOpenPhoneDialog] = useState<boolean>(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState<Array<string>>(['', '', '']);

  const [isOpenPasswordDialog, setIsOpenPasswordDialog] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newConfirmPassword, setNewConfirmPassword] = useState<string>('');
  const [isPasswordValidated, setIsPasswordValidated] = useState<boolean>(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState<boolean>(false);


  const navigate = useNavigate();
  const handleLoadMyInfo = () => {
    API.getMyInfo()
    .then((res) => {
      console.log(res);
      setUserState((prevState) => ({
        ...prevState,
        isLoggedIn: true,
        email: res.data.user_email,
        name: res.data.user_name,
        phone: res.data.user_phone,
        role_name: res.data.role_name,
        role_description: res.data.role_description,
        org_name: res.data.org_name,
        is_noti_enabled: res.data.is_noti_enabled
      }))
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleUpdateMyInfo = (is_noti_enabled: boolean, user_phone: string) => {
    API.updateMyInfo(is_noti_enabled, user_phone)
    .then((res) => {
      console.log(res);
      openNotiDialog('변경이 완료되었습니다.');
      handleLoadMyInfo();
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  };

  const handleUpdatePassword = (password: string) => {
    API.updatePassword(password)
    .then((res) => {
      console.log(res);
      openNotiDialog('변경이 완료되었습니다.');
      handleLoadMyInfo();
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  };

  useEffect(() => {
    handleLoadMyInfo();
  }, [])

  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', background: 'white', paddingBottom: 50}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-18-20'>마이페이지</span>
        </div>

        <div className='myPageInfoRow' style={{marginTop: 40, borderTop: '1px solid #4F4F4F'}}>
          <div>이름</div>
          <div className='inter800-16-20'>{userState.name}</div>
        </div>
        <div className='myPageInfoRow'>
          <div>사용자 유형</div>
          <div className='inter800-16-20'>{userState.role_description}</div>
        </div>
        <div className='myPageInfoRow'>
          <div>전화번호</div>
          <div className='inter800-16-20 flexStart'>
            <>{[userState.phone?.slice(0,3)!, userState.phone?.slice(3,7)!, userState.phone?.slice(7)!].join('-')}</>
            <button className='acceptBtn' style={{width: 175, marginLeft: 'auto'}}
            onClick={() => {
              setNewPhoneNumber([userState.phone?.slice(0,3)!, userState.phone?.slice(3,7)!, userState.phone?.slice(7)!])
              setIsOpenPhoneDialog(true);
            }}>전화번호 변경</button>
          </div>
        </div>
        <div className='myPageInfoRow' style={{borderBottom: 'none'}}>
          <div>기관 이메일</div>
          <div className='inter800-16-20'>{userState.email}</div>
        </div>
        <div className='myPageInfoRow inter500-16-20' style={{color: '#838181', marginTop: -30}}>
          <div>알림 설정</div>
          <div className='flexStart'>
            <>타 기관으로부터 통계 요청 수신 시, 알림 메일을 받습니다.</>
            <button className={userState.is_noti_enabled ? 'notiOnBtn' : 'notiOffBtn'} style={{marginLeft: 'auto'}}
              onClick={() => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  title: '알림 설정을 변경하시겠습니까?',
                  onAccept: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                    handleUpdateMyInfo(!userState.is_noti_enabled!, userState.phone!);
                  },
                  onDeny: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                  }
                }));
              }}>

            </button>
          </div>
        </div>
        <div className='myPageInfoRow'>
          <div>소속기관</div>
          <div className='inter800-16-20'>{userState.org_name}</div>
        </div>
        <div className='myPageInfoRow'>
          <div>비밀번호</div>
          <div className='inter800-16-20 flexStart'>
            <>*******</>
            <button className='acceptBtn' style={{width: 190, marginLeft: 'auto'}}
            onClick={() => {
              setIsOpenPasswordDialog(true);
            }}>비밀번호 재설정</button>
          </div>
        </div>
        
      </div>


      {/* 전화번호 변경 팝업 */}
      {isOpenPhoneDialog ?
      <div className='dialogBase' style={{zIndex: 100}}>
        <div className='dialogPaper' style={{width: 620, height: 281}}>
          <button className='self-end' style={{width: 20}}
            onClick={() => {
              setIsOpenPhoneDialog(false);
            }}>
            <img src={`./src/ic_close.png`} alt="닫기" />
          </button>

          <div className='flexColumnsCenter' style={{height: 250}}>
            <div className='inter500-18-32 text-center whitespace-pre-wrap' style={{marginTop: 0}}>
              변경할 전화번호를 입력해주세요.
            </div>

            <div style={{display: 'flex', gap: 10, lineHeight: '42px', marginTop: 30}}>
              <input 
                id='phoneInput'
                type='text'
                maxLength={3}
                value={newPhoneNumber[0]}
                style={{width: 100}}
                placeholder='010'
                className='signupInfoInput'
                onChange={(e) => {
                  setNewPhoneNumber((prevState) => {
                    return [e.target.value, prevState[1], prevState[2]]
                  })
                }}
                >
              </input>
              <>-</>
              <input 
                id='phoneInput'
                type='text'
                maxLength={4}
                value={newPhoneNumber[1]}
                style={{width: 100}}
                placeholder='1234'
                className='signupInfoInput'
                onChange={(e) => {
                  setNewPhoneNumber((prevState) => {
                    return [prevState[0], e.target.value, prevState[2]]
                  })
                }}
                >
              </input>
              <>-</>
              <input 
                id='phoneInput'
                type='text'
                maxLength={4}
                value={newPhoneNumber[2]}
                style={{width: 100}}
                placeholder='1234'
                className='signupInfoInput'
                onChange={(e) => {
                  setNewPhoneNumber((prevState) => {
                    return [prevState[0], prevState[1], e.target.value]
                  })
                }}
                >
              </input>
            </div>

            <div className='flex gap-[18px]' style={{marginTop: 30}}>
              <button className='acceptBtn self-center' style={{width: 110}}
                onClick={() => {
                  console.log(isNumber(newPhoneNumber.join('')))

                  if (!isNumber(newPhoneNumber.join(''))) {
                    openNotiDialog('전화번호가 올바르지 않습니다')
                    return;
                  }

                  handleUpdateMyInfo(userState.is_noti_enabled!, newPhoneNumber.join(''));
                  setIsOpenPhoneDialog(false);
                }}>변경</button>
              <button className='outlineBtn self-center' style={{width: 110}}
              onClick={() => {
                setIsOpenPhoneDialog(false);
              }}>취소</button>
            </div>
          </div>

        </div>

      </div>
      : null}

      {/* 비밀번호 재설정 팝업 */}
      {isOpenPasswordDialog ?
      <div className='dialogBase' style={{zIndex: 100}}>
        <div className='dialogPaper' style={{width: 620, height: 320}}>
          <button className='self-end' style={{width: 20}}
            onClick={() => {
              setIsOpenPasswordDialog(false);
            }}>
            <img src={`./src/ic_close.png`} alt="닫기" />
          </button>

          <div className='flexColumnsCenter' style={{height: 250}}>
            <div className='inter500-18-32 text-center whitespace-pre-wrap' style={{marginTop: 0}}>
              변경할 비밀번호를 입력해주세요.<br/>
            </div>
            <div className='inter400-12-15'>(영문 + 숫자 + 특수문자 10자 이상)</div>


            <div style={{display: 'grid', height: 42, borderBottom: 'none', gridTemplateColumns: '125px 320px 1fr 24px', lineHeight: '42px', marginTop: 32, alignItems: 'center'}}>
              <div>비밀번호</div>
              <input 
                id='pwInput'
                type='password'
                className='signupInfoInput'
                placeholder='비밀번호 (password)'
                style={{width: 300, fontSize: 13}}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setIsPasswordValidated(validatePassword(e.target.value));
                  setIsPasswordMatched(newConfirmPassword === e.target.value);
                }}
                >
              </input>
              <div style={{width: 24, height: 24, marginLeft: -60}}>
                <img src={isPasswordValidated ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
              </div>
            </div>
            <div style={{display: 'grid', height: 42, gridTemplateColumns: '125px 320px 1fr 24px', lineHeight: '42px', marginTop: 10, alignItems: 'center'}}>
              <div>비밀번호 확인</div>
              <input 
                id='pwConfirmInput'
                type='password'
                className='signupInfoInput'
                placeholder='비밀번호 확인  ( Confirm password)'
                style={{width: 300, fontSize: 13}}
                onChange={(e) => {
                  setNewConfirmPassword(e.target.value);
                  setIsPasswordMatched(newPassword === e.target.value);
                }}
                >
              </input>
              <div></div>
              <div style={{width: 24, height: 24, marginLeft: -60}}>
                <img src={isPasswordMatched ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
              </div>
            </div>

            <div className='flex gap-[18px]' style={{marginTop: 30}}>
              <button className='acceptBtn self-center' style={{width: 110}}
                onClick={() => {
                  if (!isPasswordMatched || !isPasswordValidated) {
                    openNotiDialog('비밀번호가 올바르지 않습니다.');
                    return;
                  }
                  handleUpdatePassword(newPassword);
                  setIsOpenPasswordDialog(false);
                }}>변경</button>
              <button className='outlineBtn self-center' style={{width: 110}}
              onClick={() => {
                setIsOpenPasswordDialog(false);
              }}>취소</button>
            </div>
          </div>

        </div>

      </div>
      : null}


    </>
  );
}