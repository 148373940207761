import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import * as API from '../APIProvider'
import { useRecoilState } from 'recoil';
import { DialogInfoState, Notice, ShinyLoadingState, UserInfoState } from '../interfaces';
import { dialogInfoState, userInfoState } from '../globalState';
import { dateToYMD, dateToYMDHHMM, openNotiDialogWithSetter } from '../util';
import SimpleTooltip from '../components/SimpleTooltip';

const RSHINY_HOST = process.env.REACT_APP_RSHINY_HOST;

export default function MainPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const [loadingState, setLoadingState] = useState<ShinyLoadingState>({
    app_chart_age60: false,
    app_chart_males: false,
    app_chart_females: false,
    app_chart_total_symptom: false
  });

  const [noticeData, setNoticeData] = useState<Array<Notice>>([]);

  const handleLoadNotice = () => {
    API.getAllNotice()
    .then((res) => {
      setNoticeData(res.data.slice(0,5));
    })
    .catch((err) => {
      //openNotiDialog('오류가 발생했습니다.');

    })
  };

  useEffect(() => {
    if (userState.isLoggedIn) {
      handleLoadNotice();
    }
  }, [userState.isLoggedIn])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', paddingBottom: userState.isLoggedIn ? 44 : 44}}>

        <div className='mainBannerCard'>
          <p>
            감염병 환자정보관리 통합 시스템 
            <span>
              <img src={`./src/logo.png`} style={{width: 120, display: 'inline-block', padding: '0px 8px'}} />
            </span> 는<br/>
            통합적 역학정보 체계 및 환자정보수집 체계를 구축하여<br/>
            근거 기반 예방적 방역을 선도합니다.<br/>
          </p>
          <img className='' src={`./src/banner_bg.png`} alt="main banner" style={{ position: 'absolute', height: 243, marginLeft: 240}}/>
        </div>
        {/* <img className='contentFullWidth' style={{}} src={`./src/main_banner_top.png`} alt="main banner" /> */}

        <div className='flexStart' style={{width: 1200, marginTop: 45, height: 42}}>
          <span className='inter800-20-20'>TREND</span>
          <SimpleTooltip 
            message={`일자별 특정 증상에 대한 환자수와 추후 예측 환자수를 확인할 수 있습니다.\n각 차트별로 위장관 증상, 인플루엔자, 호흡기 증상, 호흡기 증상 2주내 기계환기 그룹에 대한 데이터를 나타냅니다.`}/>
          {/* <span className='inter400-16-20'>&nbsp; /&nbsp; Influenza</span> */}
          <select className='squareSelect inter600-16-20'
            value={""}
            style={{width: 315, backgroundPosition: 275, backgroundImage: 'url(./src/ic_select_arrow.png)', marginLeft: 'auto', color: '#616161', borderRadius: '21px'}}
            onChange={(e) => {
            }}>
            <option value="influenza" selected>Influenza</option>
          </select>
        </div>
        <div className='mainGrid' style={{marginTop: 20}}>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
            <img src={`./src/chart/functional_gastrointestinal_disorder.png`} alt= "functional gastrointestinal disorder" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
            <img src={`./src/chart/influenza.png`} alt= "influenza" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
            <img src={`./src/chart/respiratory_organ.png`} alt= "respiratory organ" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
            <img src={`./src/chart/respiratory_organ2.png`} alt= "respiratory organ" style={{height: '100%', marginLeft: 10}}/>
          </div>

        </div>
{/*
        //이전 버전
        <div className='mainGrid' style={{marginTop: 20}}>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
           <img src={`${RSHINY_HOST}/chart_total_symptom.png`} alt= "chart for total symptom" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
           <img src={`${RSHINY_HOST}/chart_age60.png`}alt= "chart for under age 60" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
           <img src={`${RSHINY_HOST}/chart_males.png`} alt= "chart for males" style={{height: '100%', marginLeft: 10}}/>
          </div>
          <div className='iframeCard flexCenter' style={{width: 621, height: 394, background: 'white'}}>
           <img src={`${RSHINY_HOST}/chart_females.png`}alt= "chart for females" style={{height: '100%', marginLeft: 10}}/>
          </div>
          
        </div>
*/}

        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 36, marginTop: 52}}>
          <div className='bannerCard' onClick={() => {navigate('/insight/group')}}>
            <div style={{display: 'flex'}}>
              <div className='inter800-20-20'>집단수준 감염통계</div>

              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4, marginLeft: 'auto'}}>
                <div className='inter500-14-20'>바로가기</div>
                <img src={`./src/ic_arrow_right_banner.svg`} alt="arrow" style={{marginLeft: 'auto'}}/>
              </div>

            </div>
            <div className='inter500-20-35' style={{marginTop: 20}}>추적 중인 감염병 별 PCR 현황,<br/>지역별 / 중증도별 / 증상별 통계</div>
          </div>

          <div className='bannerCard' onClick={() => {navigate('/insight/individual')}}>
            <div style={{display: 'flex'}}>
              <div className='inter800-20-20'>감염환자 임상정보</div>

              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4, marginLeft: 'auto'}}>
                <div className='inter500-14-20'>바로가기</div>
                <img src={`./src/ic_arrow_right_banner.svg`} alt="arrow" style={{marginLeft: 'auto'}}/>
              </div>

            </div>
            <div className='inter500-20-35' style={{marginTop: 20}}>환자 별 증상 및 진단, 치료 과정 중<br/>약물 투여 현황 등 임상정보</div>
          </div>

          <div className='bannerCard' onClick={() => {navigate('/stats/create')}}>
            <div style={{display: 'flex'}}>
              <div className='inter800-20-20'>통계 요청</div>

              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 4, marginLeft: 'auto'}}>
                <div className='inter500-14-20'>바로가기</div>
                <img src={`./src/ic_arrow_right_banner.svg`} alt="arrow" style={{marginLeft: 'auto'}}/>
              </div>
            </div>
            <div className='inter500-20-35' style={{marginTop: 20}}>타 의료기관에 코호트 별 통계 요청</div>
          </div>
        </div>


        <div className='flexStart' style={{width: 1200, marginTop: 45, height: 42}}>
          <span className='inter800-20-20'>참여기관</span>
        </div>

        <div className='mainBannerCard' style={{display: 'flex', justifyContent: 'center', height: 134, gap: 68}}>
          
          <img className='' src={`./src/logo_hospital_1.png`} alt="participating organization 1" style={{ height: 44 }}/>
          <img className='' src={`./src/logo_hospital_2.png`} alt="participating organization 2" style={{ height: 44 }}/>
          <img className='' src={`./src/logo_hospital_3.png`} alt="participating organization 3" style={{ height: 44 }}/>
          <img className='' src={`./src/logo_hospital_4.svg`} alt="participating organization 4" style={{ height: 40, marginTop: -6 }}/>
        </div>




      </div>

      

      {userState.isLoggedIn ?
      <div className='bluePaper flexColumnsStart' style={{minHeight: 'unset', background: 'white', paddingBottom: 100}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-18-20'>공지사항</span>
        </div>

        <div className='flexEnd' style={{width: 1200, marginTop: 8}}>
          <div onClick={() => navigate('/notice')} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 8}}>
            <div className='inter600-16-20'>더보기</div>
            <img src={`./src/ic_arrow_right_banner.svg`} alt="arrow" style={{marginLeft: 'auto'}}/>
          </div>
          
        </div>

        <div className='noticeTopRow inter600-16-20' style={{marginTop: 40, borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', cursor: 'unset'}}>
          <div>번호</div>
          <div>제목</div>
          <div>날짜</div>
          <div>작성자</div>
        </div>

        {
          noticeData.map((item, idx) => {
            return (
              <div className='noticeItemRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: idx+1 === noticeData.length ? '1px solid #4F4F4F' : 'none'}}
                onClick={() => {
                  navigate(`/notice/detail/${item.id}`)
                }}>
                <div>{item.id}</div>
                <div>{item.title}</div>
                <div>{dateToYMD(item.created_at)}</div>
                <div style={{textAlign: 'center'}}>{item.created_by.user_name}</div>
              </div>
            )
          })
        }
    

      </div>
      : null}
    </>
  );
}