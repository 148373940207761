import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState, PageState, UserInfoState } from '../interfaces';
import { dialogInfoState, userInfoState } from '../globalState';
import * as API from '../APIProvider'
import { openNotiDialogWithSetter } from '../util';
import axios from 'axios';

export default function MainFooter() {
  
  
  return (
  <footer className='flexCenter' style={{height: 100, borderTop: '1px solid #9BA7B8', background: 'white', bottom: 0}}>
    <div className='innerFooter'>
      <img src={`./src/footer_logo1.png`} style={{width: 240}} />
      <img src={`./src/footer_logo2.png`} style={{width: 160, marginLeft: 53}} />
      <div className='inter400-13-16' style={{marginLeft: 393, textAlign: 'right'}}>
        <div>PHAROS 계정 및 오류 문의 | omop_support@evidnet.co.kr</div>
        <div style={{marginTop: 8, color: '#5A6370'}}>© 2023. evidnet Inc. ALL RIGHTS RESERVED.</div>
      </div>

    </div>

  </footer>
  );
}