import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState } from '../interfaces';
import { dialogInfoState } from '../globalState';

export default function CommonDialog() {
  
  const navigate = useNavigate();

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);


  return (

  <div className='dialogBase' style={{zIndex: 110}}>
    <div className='dialogPaper' style={{width: 460, height: 281}}>
      <button className='self-end' style={{width: 20}}
        onClick={() => {
          setDialogState((prevState) => ({
            ...prevState,
            isOpen: false
          }));
        }}>
        <img src={`./src/ic_close.png`} alt="닫기" />
      </button>

      <div className='flexColumnsCenter' style={{height: 230}}>
        <div className='inter500-18-32 text-center whitespace-pre-wrap'>
          {dialogState.title}
        </div>

        <div className='flex gap-[18px]' style={{marginTop: 36}}>
          <button className='acceptBtn self-center' style={{width: 110}}
            onClick={() => {
              dialogState.onAccept();
            }}>확인</button>
          {dialogState.onDeny ?
          <button className='outlineBtn self-center' style={{width: 110}}
          onClick={() => {
            setDialogState((prevState) => ({
              ...prevState,
              isOpen: false
            }));
          }}>취소</button> : null}
        </div>
      </div>

    </div>

  </div>  

  );
}