import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import {DialogInfoState, SetTrendTimed} from '../../interfaces';
import * as API from '../../APIProvider'
import { openTrendTimeDialogWithSetter } from '../../util';

export default function ChangeSituationPage() {

    const navigate = useNavigate();
    const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);

    const trendTimeDialog = (title: string) => {
        openTrendTimeDialogWithSetter(setDialogState, title);
    }
    const [setTrendTimed, setTrendTime] = useState<SetTrendTimed>();


    const handleLoadTrendTime = () => {

        API.getTrendTimed()
            .then((res) => {
                setTrendTime({
                    trendtime: res.data.lastStatus.trendtime_trend_time
                });
            })
            .catch((err) => {
                trendTimeDialog('오류가 발생했습니다.');
            })
    }

    const handleUpdateTrendTimeEnabled = (trendtime: boolean) => {
        console.log("----1");
        console.log(trendtime);
        API.setTrendTimed({
            'trendtime': trendtime
        })
            .then((res) => {
                trendTimeDialog('변경이 완료되었습니다.');
                handleLoadTrendTime();
                console.log("setting");
                console.log(trendtime);
            })
            .catch((err) => {
                trendTimeDialog('오류가 발생했습니다.');
            })
    }

    useEffect(() => {
        handleLoadTrendTime();

    }, [])

    return (
        <>
            <div className='bluePaper'>
                <div className='flexStart contentWidth' style={{marginTop: 38}}>
                    <span className='inter800-20-20'>감염병 유행시 설정</span>
                </div>

                <div className='mainWideCard' style={{marginTop: 28, height: '60vh', padding: '36px 49px'}}>
                    <div className='flexColumnsCenter inter800-16-20' style={{width: 1174, height: '100%'}}>
                        <div className='inter500-18-36'>
                            본 설정은 감염병 통합 시스템의 운영 상태를 "유행시"로 변경하여 <br/> <b><u>참여 병원의 감염병 예방</u></b> 및 <b><u>감염병 유증상 응급환자</u></b>에 대응하여 응급의료기관 운영에 참고할 수 있도록 합니다.
                        </div>
                        <button className={setTrendTimed?.trendtime ? 'trendTimedOnBtn' : 'trendTimedOffBtn'} style={{marginTop: 60}}
                                onClick={() => {
                                    setDialogState((prevState) => ({
                                        ...prevState,
                                        isOpen: true,
                                        title: '설정을 변경하시겠습니까?',
                                        onAccept: () => {
                                            setDialogState((prevState) => ({
                                                ...prevState,
                                                isOpen: false
                                            }))
                                            handleUpdateTrendTimeEnabled(!setTrendTimed?.trendtime);
                                        },
                                        onDeny: () => {
                                            setDialogState((prevState) => ({
                                                ...prevState,
                                                isOpen: false
                                            }))
                                        }
                                    }));
                                }}>

                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}