import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { DialogInfoState, PageState, SearchState, UpdatableUserInfo, UserInfoData, UserRole, WithdrawUserInfo } from '../../interfaces';
import * as API from '../../APIProvider'
import { dateToYMDHHMM, onClickSelectAll, openNotiDialogWithSetter, searchAndUpdateDataWithTargetKey, updateSelectAll } from '../../util';
import { cloneDeep } from 'lodash';
import CommonPageNavi from '../../components/CommonPageNavi';


export default function UsersPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })
  const [userData, setUserData] = useState<Array<UserInfoData>>([]);
  const [rawUserData, setRawUserData] = useState<Array<UserInfoData>>([]);
  const [roleData, setRoleData] = useState<Array<UserRole>>([]);
  const [checkedUsers, setCheckedUsers] = useState<Array<WithdrawUserInfo>>([]);

  const [searchState, setSearchState] = useState<SearchState>({
    keyword: '',
    targetKey: 'user_email'
  });

  const [userDialogState, setUserDialogState] = useState<DialogInfoState>({
    isOpen: false,
    title: '회원 설정',
    desc: '',
    onAccept: () => {
      setUserDialogState((prevState) => ({
        ...prevState,
        isOpen: false
      }))
    },
    onDeny: undefined
  });
  const [selectedUser, setSelectedUser] = useState<UserInfoData>();
  const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false);


  const handleLoadUsers = () => {
    API.getAdminAllUsers()
    .then((res) => {
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      })
      setUserData(res.data);
      setRawUserData(res.data);
      setCheckedUsers(res.data.map((item:UserInfoData) => {
        return ({
          checked: false,
          user_id: item.user_id,
          role_id: item.role_id
        })
      }))
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  };

  const handleLoadUserRole = () => {
    API.getUserRole()
    .then((res) => {
      setRoleData(res.data)
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleWithdrawUsers = async () => {
    try {
      let promises:Array<any> = [];

      checkedUsers.forEach((item, idx) => {
        if (!item.checked) return;
        promises.push(API.withdrawUser(item.user_id))
      })

      await Promise.all(promises)
      .then((res) => {
        setDialogState((prevState) => ({
          ...prevState,
          isOpen: true,
          title: '강제 탈퇴 처리를 완료했습니다.',
          onAccept: () => {
            setDialogState((prevState) => ({
              ...prevState,
              isOpen: false
            }))
            handleLoadUsers();
          },
          onDeny: undefined
        }));
      })
      .catch((err) => {
        console.log(err);
        openNotiDialog('오류가 발생했습니다.');
        return;

      })

      
    }
    catch (e) {
      console.log(e);
      openNotiDialog('오류가 발생했습니다.');
    }
  }

  const handleUserPasswordReset = (id: number) => {
    API.requestAdminPasswordResetMail(id)
    .then((res) => {
      openNotiDialog('비밀번호 재설정 이메일을 발송했습니다.');
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  const handleUpdateUserInfo = (id: number) => {

    const info:UpdatableUserInfo = {
      user_role_id: selectedUser?.role_id
    };

    API.updateAdminUserInfo(id, info)
    .then((res) => {
      setUserDialogState((prevState) => ({
        ...prevState,
        isOpen: false
      }))
      openNotiDialog('회원 정보 업데이트를 완료했습니다.');
      handleLoadUsers();
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }


  useEffect(() => {
    handleLoadUsers();
    handleLoadUserRole();
  }, [])


  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>회원 목록</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '36px 49px'}}>
          <div className='flexStart inter800-16-20' style={{width: 1174}}>
           
            <select className={cn('squareSelect')} name="searchType" id="searchTypeSelect" 
              value={searchState.targetKey}
              style={{backgroundImage: 'url(./src/ic_select_arrow.png)'}}
              onChange={(e) => {
                setSearchState((prevState) => ({
                  ...prevState,
                  targetKey: e.target.value
                }))
              }}>
              <option value="user_email" selected>아이디</option>
              <option value="user_name">성명</option>
              <option value="org_name">기관</option>
            </select>

            <input 
              id='searchInput'
              type='text'
              className='squareInput'
              placeholder=''
              style={{marginLeft: 8}}
              onChange={(e) => {
                setSearchState((prevState) => ({
                  ...prevState,
                  keyword: e.target.value
                }))
                searchAndUpdateDataWithTargetKey(e.target.value, searchState.targetKey!, rawUserData, setUserData, setPageState)
              }}
              />
            <img src={searchState.keyword === '' ? `./src/ic_search.png` : `./src/ic_search_reset.png`} alt="reset button" style={{width: 20, height: 20, cursor: 'pointer', marginLeft: -30}}
              onClick={() => {
                if (searchState.keyword === '') return;
                setSearchState((prevState) => ({
                  ...prevState,
                  keyword: ''
                }));
                setUserData(rawUserData);
              }}/>


            <button className='acceptBtn self-center' style={{width: 141, marginLeft: 'auto'}}
              onClick={() => {
              
                if (checkedUsers.filter((v) => v.checked === true).length <= 0) {
                  openNotiDialog('선택된 회원이 없습니다.')
                  return;
                }

                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  title: '선택된 회원에 대한 강제 탈퇴를 진행하시겠습니까?',
                  onAccept: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                    handleWithdrawUsers();
                  },
                  onDeny: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                  }
                }));

              }}>강제탈퇴</button>
          
          </div>

          <div className='signupRequestsRow' style={{marginTop: 40, borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600}}>
            <div className='defaultCheckbox'>
              <input
                type='checkbox'
                name='cbAll'
                id={`cbAll`}
                onChange={(e) => {
                  onClickSelectAll(e.target.checked);

                  let users = cloneDeep(checkedUsers);
                  users.forEach((v) => {
                    v.checked = e.target.checked;
                  });
                  
                  setCheckedUsers(users);
                }}
              />
              <label htmlFor={`cbAll`}></label>
            </div>
            <div>아이디</div>
            <div>성명</div>
            <div>소속기관</div>
            <div>사용자 유형</div>
            <div>설정</div>
          </div>

          <form>
          {
            userData?.slice(pageState.idx!*10+0, pageState.idx!*10+10).map((item, idx, arr) => {
              return (
                <div className='signupRequestsRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: (idx+1 === arr.length || (idx+1) %10 === 0) ? '1px solid #4F4F4F' : 'none'}}>
                  <div className='defaultCheckbox'>
                    <input
                      type='checkbox'
                      name='cb'
                      id={`cb${idx}`}
                      onChange={(e) => {
                        let users = cloneDeep(checkedUsers);
                        users[idx].checked = e.target.checked;
                        setCheckedUsers(users);
                        updateSelectAll();
                      }}
                    />
                    <label htmlFor={`cb${idx}`}></label>
                  </div>
                  <div>{item.user_email}</div>
                  <div>{item.user_name}</div>
                  <div>{item.org_name}</div>
                  <div>{roleData[item.role_id-1]?.role_description}</div>
                  
                  <div className='blueHiglightableBtn'
                    onClick={() => {
                      setUserDialogState((prevState) => ({
                        ...prevState,
                        isOpen: true
                      }))
                      setSelectedUser(item);
                      setIsUserUpdated(false);
                    }}>
                    조회
                  </div>
                </div>
              )}
            )
          }
          </form>


          <CommonPageNavi pageState={pageState} setPageState={setPageState}/>



        </div>
        

      </div>

      {/* 기관 선택 dialog */}
      {userDialogState.isOpen ?

      <div className='dialogBase'>
        <div className='dialogPaper' style={{width: 460, maxHeight: 439}}>
          <button className='self-end' style={{width: 20}}
            onClick={() => {
              setUserDialogState((prevState) => ({
                ...prevState,
                isOpen: false
              }));
            }}>
            <img src={`./src/ic_close.png`} alt="닫기" />
          </button>

          <div className='flexColumnsStart' style={{marginTop: 3}}>
            <div className='inter600-16-20 text-center whitespace-pre-wrap'>
              {userDialogState.title}
            </div>

            <div style={{width: 408, borderTop: '1px solid #DCE5F3', marginTop: 20}}/>
            
            <div className='inter500-14-20' style={{display: 'grid', gridTemplateColumns: '2fr 3fr', width: 320, marginTop: 22, gap: 12}}>
              <div>성명</div>
              <div className='inter600-14-20'>{selectedUser?.user_name}</div>
              <div>아이디</div>
              <div className='inter600-14-20'>{selectedUser?.user_email}</div>
              <div>소속기관</div>
              <div className='inter600-14-20'>{selectedUser?.org_name}</div>

              <div style={{height: 32, lineHeight: '32px'}}>사용자 유형</div>
              <select className='userTypeSelect inter600-14-20' name="userType" id="userTypeSelect" 
                value={selectedUser?.role_id}
                style={{border: '1px solid #5A6370', backgroundImage: 'url(./src/ic_arrow_bottom.png)', fontSize: 14, height: 32, width: 160, backgroundPosition: '136px'}}
                onChange={(e) => {
                  let user = cloneDeep(selectedUser);
                  if (!user) return;
                  user.role_id = Number(e.target.value);
                  setSelectedUser(user);
                  setIsUserUpdated(true);
                }}>
                {roleData.map((r, i) => {
                  return (
                    <option value={r.role_id}>{r.role_description}</option>
                  )
                })}
              </select>

              <div style={{height: 32, lineHeight: '32px'}}>비밀번호</div>
              <button className='outlineBtn' style={{width: 160, height: 32, fontSize: 14}}
                onClick={() => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    isOpen: true,
                    title: '비밀번호 재설정 링크를 전송하시겠습니까?',
                    onAccept: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                      handleUserPasswordReset(Number(selectedUser?.user_id));
                    },
                    onDeny: () => {
                      setDialogState((prevState) => ({
                        ...prevState,
                        isOpen: false
                      }))
                    }
                  }));
                }}>비밀번호 재설정</button>

            </div>


            <div className='flex gap-[18px]' style={{marginTop: 28, marginBottom: 28}}>
              <button className='acceptBtn self-center' style={{width: 110}}
                onClick={() => {
                  if (!isUserUpdated) {
                    userDialogState.onAccept()
                  }
                  else {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: true,
                      title: '회원 정보를 업데이트하시겠습니까?',
                      onAccept: () => {
                        setDialogState((prevState) => ({
                          ...prevState,
                          isOpen: false
                        }))
                        handleUpdateUserInfo(Number(selectedUser?.user_id));
                      },
                      onDeny: () => {
                        setDialogState((prevState) => ({
                          ...prevState,
                          isOpen: false
                        }))
                      }
                    }));
                  }
                }}>확인</button>
            </div>
          </div>

        </div>

      </div> 
      : null }
    </>
  );
}