import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { DialogInfoState, Setting } from '../../interfaces';
import * as API from '../../APIProvider'
import { openNotiDialogWithSetter } from '../../util';

export default function NotificationControlPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [setting, setSetting] = useState<Setting>();

  const handleLoadSetting = () => {
    API.getAdminSetting()
    .then((res) => {
      setSetting(res.data)
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  const handleUpdateNotiEnabled = (is_noti_enabled: boolean) => {
    API.updateAdminSetting({
      'is_noti_enabled': is_noti_enabled
    })
    .then((res) => {
      openNotiDialog('변경이 완료되었습니다.');
      handleLoadSetting();
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  useEffect(() => {
    handleLoadSetting();

  }, [])

  return (
    <>
      <div className='bluePaper'>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>메일 알림 설정</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: '60vh', padding: '36px 49px'}}>
          <div className='flexColumnsCenter inter800-16-20' style={{width: 1174, height: '100%'}}>
            <div className='inter500-18-36'>
              각 사용자는 타 기관으로부터 통계 요청 수신 시, 알림 메일을 받습니다.
            </div>
            <button className={setting?.is_noti_enabled ? 'notiOnBtn' : 'notiOffBtn'} style={{marginTop: 60}}
              onClick={() => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  title: '설정을 변경하시겠습니까?',
                  onAccept: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                    handleUpdateNotiEnabled(!setting?.is_noti_enabled);
                  },
                  onDeny: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                  }
                }));
              }}>

            </button>
          
          </div>



        </div>
        

      </div>
    </>
  );
}