import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState, userInfoState } from '../../globalState';
import { DialogInfoState, Org, PageState, PopupInfoState, StatRequestItem, StatResultTable, TableDialogInfoState, UserInfoState } from '../../interfaces';
import * as API from '../../APIProvider'
import { dateToYMDHHMM, downloadExcelFile, openNotiDialogWithSetter } from '../../util';
import CommonPageNavi from '../../components/CommonPageNavi';

export default function StatsResultPage() {

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [popupState, setPopupState] = useState<PopupInfoState>({
    isOpen: false,
    itemIdx: 0,
    type: 'request'
  });

  const [statData, setStatData] = useState<Array<StatRequestItem>>();
  const [statResultData, setStatResultData] = useState<StatResultTable>();

  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })

  const [tableDialogState, setTableDialogState] = useState<TableDialogInfoState>({
    isOpen: false,
    tab: 'person'
  });

  const handleLoadRequestedStats = () => {
    API.getRequestedStats()
    .then((res) => {
      setStatData(res.data);
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      })

    })
    .catch((err) => {
      console.log(err)
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleLoadStatResultTable = (id: number) => {
    API.getStatResultTable(id)
    .then((res) => {
      console.log(res.data)
      setStatResultData(res.data);

    })
    .catch((err) => {
      console.log(err)
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  
  



  useEffect(() => {
    handleLoadRequestedStats();
  }, []);


  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>통계 요청 내역</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '59px 49px'}}>

          <div className='statResultRow' style={{borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600}}>
            <div>요청 일시</div>
            <div>수신기관</div>
            <div>코호트</div>
            <div>요청 코멘트</div>
            <div>상태</div>
            <div>처리 일시</div>
            <div>답신 코멘트</div>
            <div>통계</div>

          </div>

          <form>
          {statData?.slice(pageState.idx!*10+0, pageState.idx!*10+10).map((item, idx, arr) => {
              return (
              <>
                <div className='statResultRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: (idx+1 === arr.length || (idx+1) %10 === 0) ? '1px solid #4F4F4F' : 'none'}}>
                  <div>{dateToYMDHHMM(item.requested_at)}</div>
                  {/* <div>{orgData?.find(o => o.id === item.recipient_org_id)?.name}</div> */}
                  <div>{item.recipient_org_name}</div>
                  <div style={{width: 120, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.cohort_name}<br/>({item.cohort_id})</div>

                  <div className='flex' style={{cursor: 'pointer'}}
                    onMouseEnter={() => {
                      setPopupState({
                        isOpen: true,
                        itemIdx: idx,
                        type: 'request'
                      })
                    }}
                    onMouseLeave={() => {
                      setPopupState({
                        isOpen: false
                      })
                    }}
                    
                    >
                    <>확인</>
                    <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                  </div>


                  <div>
                    {
                      item.status === 'pending' ? '대기' : 
                      item.status === 'denied' ? '거절' : 
                      item.status === 'completed' ? '완료' : 
                      item.status === 'data-processing' ? '전송중' :
                      '에러'
                     }
                  </div>

                  <div>{item.responded_at !== null ? dateToYMDHHMM(item.responded_at) : '-'}</div>
                  {item.respond_comment === null || item.respond_comment === ''
                  ?
                  <div>-</div>
                  :
                  <div className='flex' style={{cursor: 'pointer'}}
                    onMouseEnter={() => {
                      if (item.respond_comment === null || item.respond_comment === '') return;
                      setPopupState({
                        isOpen: true,
                        itemIdx: idx,
                        type: 'response'
                      })
                    }}
                    onMouseLeave={() => {
                      setPopupState({
                        isOpen: false
                      })
                    }}
                    >
                    <>확인</>
                    <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                  </div>
                  }
                  <div className='flex' style={{gap: 5}}>
                    <button type="button" className='outlineBtn self-center' style={{width: 66, height: 28}}
                      onClick={() => {
                        handleLoadStatResultTable(item.id);
                        setTableDialogState((prevState) => ({
                          ...prevState,
                          isOpen: true,
                          tab: 'person'
                        }));
                      }}>열람</button>
                    <button type="button" className='acceptBtn flexCenter' style={{width: 85, height: 28, gap: 1}}
                      onClick={() => {
                        downloadExcelFile(statResultData!);
                    }}>
                      <img src={`./src/ic_download.png`} alt="다운로드" style={{width: 20}}/>

                      <div style={{fontSize: 14}}>XLSX</div>
                      </button>
                  </div>
                </div>
                <div className='floatingComment' style={{visibility: popupState.isOpen && popupState.itemIdx === idx ? 'visible' : 'hidden', marginLeft: popupState.type === 'request' ? 360 : 740}}>
                  {popupState.type === 'request' ? item.request_comment : item.respond_comment ?? '-'}
                </div>
              </>
              )
            })
          }
          </form>


            
          <CommonPageNavi pageState={pageState} setPageState={setPageState}/>

        </div>

      </div>



      {/* 기관 선택 dialog */}
      {tableDialogState.isOpen ?

      <div className='dialogBase'>
        <div className='dialogPaper' style={{width: 842, padding: '24px 37px', boxSizing: 'border-box'}}>

          <button className='self-end' style={{width: 20, height: 20, zIndex: 1000}}
            onClick={() => {
              setTableDialogState((prevState) => ({
                ...prevState,
                isOpen: false
              }));
            }}>
            <img src={`./src/ic_close.png`} alt="닫기" />
          </button>
          
          <div style={{display: 'flex', gap: 15, marginTop: -10}}>
            <button className={cn({'highlightedTableTabBtn': tableDialogState.tab === 'person'}, 'tableTabBtn', 'inter600-14-20')}
              onClick={() => {
                setTableDialogState((prevState) => ({
                  ...prevState,
                  tab: 'person'
                }))
              }}>
              사람에 따라
            </button>
            <button className={cn({'highlightedTableTabBtn': tableDialogState.tab === 'event'}, 'tableTabBtn', 'inter600-14-20')}
              onClick={() => {
                setTableDialogState((prevState) => ({
                  ...prevState,
                  tab: 'event'
                }))
              }}>
              이벤트에 따라
            </button>
          </div>

          <div className='statTableTopRow' style={{marginTop: 28}}>
            <div className='statTableRowItem'></div>
            <div className='statTableRowItem'>일치 비율</div>
            <div className='statTableRowItem'>일치된 건 수</div>
            <div className='statTableRowItem'>총 이벤트 </div>
          </div>
          <div className='statTableRow'>
            <div className='statTableRowItem'>통계 요약</div>
            <div className='statTableRowItem'>{tableDialogState.tab === 'event' ? statResultData?.event?.summary.percentMatched : statResultData?.person?.summary.percentMatched}</div>
            <div className='statTableRowItem'>{tableDialogState.tab === 'event' ? statResultData?.event?.summary.finalCount : statResultData?.person?.summary.finalCount}</div>
            <div className='statTableRowItem'>{tableDialogState.tab === 'event' ? statResultData?.event?.summary.baseCount : statResultData?.person?.summary.baseCount}</div>
          </div>

          <div className='statTableTopRow' style={{marginTop: 40, gridTemplateColumns: '45px 302px 84px 163px 1fr'}}>
            <div className='statTableRowItem'></div>
            <div className='statTableRowItem'>포함 규칙</div>
            <div className='statTableRowItem'>N</div>
            <div className='statTableRowItem'>% 만족</div>
            <div className='statTableRowItem'>% 증가(To-Gain)</div>
          </div>
          {
            (tableDialogState.tab === 'event' ? statResultData?.event?.inclusionRuleStats : statResultData?.person?.inclusionRuleStats)
            ?.map((item:any, idx:number) => {
              return(
                <div className='statTableRow' style={{gridTemplateColumns: '45px 302px 84px 163px 1fr'}}>
                  <div className='statTableRowItem'>{idx+1}</div>
                  <div className='statTableRowItem'>{item.name}</div>
                  <div className='statTableRowItem'>{item.countSatisfying}</div>
                  <div className='statTableRowItem'>{item.percentSatisfying}</div>
                  <div className='statTableRowItem'>{item.percentExcluded}</div>
                </div>
              )
            })
          }


        </div>


      </div> 
      : null }
    </>
  );
}