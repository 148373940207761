import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TERM } from '../../strings';
import * as API from '../../APIProvider'
import { DialogInfoState, PageState, SignupInfo, SignupState } from '../../interfaces';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { leftPad, openNotiDialogWithSetter, validateEmail, validatePassword } from '../../util';

export default function SignupPage() {

  const navigate = useNavigate();

  // states
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [pageState, setPageState] = useState<PageState>({
    page: 'term'
  })
  const [signupInfo, setSignupInfo] = useState<SignupInfo>({
    email: '',
    password: '',
    password_confirm: '',
    email_token: '',
    name: '',
    phone_first_number: '',
    phone_middle_number: '',
    phone_last_number: ''
  })
  const [signupState, setSignupState] = useState<SignupState>({
    isEmailValidated: false,
    isVerifyRequested: false,
    verifyCode: '',
    isCodeVerified: false,
    isPasswordValidated: false,
    isPasswordMatched: false
  })
  const [verifySec, setVerifySec] = useState<number>(0);
  const [isStepCleared, setIsStepCleared] = useState<boolean>(false);

  // API functions
  const handleRequestVerify = () => {

    if (!signupState.isEmailValidated) {
      openNotiDialog('이메일 형식이 올바르지 않습니다.');
      return;
    }
    
    API.requestEmailVerification(signupInfo.email)
    .then((res) => {
      openNotiDialog('인증번호가 전송되었습니다.');
      setSignupState((prevState) => ({
        ...prevState,
        isVerifyRequested: true
      }));
      clearInterval(verifyTimerTick)
      setVerifySec(300)
      verifyTimerTick = setInterval(() => {
        setVerifySec((prevState) => {

          if (prevState === 0) {
            clearInterval(verifyTimerTick);
            return 0
          }
          else return prevState-1
          
        })
      }, 1000)
    })
    .catch((err) => {
      if (err.response.status === 400) {
        console.log(err.response.data);

        if (err.response.data.message.includes('already registered')) {
          openNotiDialog('이미 가입된 이메일입니다.');
        }
        else {
          openNotiDialog('등록된 기관의 이메일이 아닙니다.');
        }
      }
      else {
        openNotiDialog('오류가 발생했습니다.');
      }
    })
  }

  const handleCheckVerifyCode = () => {
    API.checkEmailVerification(signupInfo.email, signupState.verifyCode)
    .then((res) => {
      console.log(res.data['email-token'])
      setSignupState((prevState) => ({
        ...prevState,
        isCodeVerified: true
      }));
      setSignupInfo((prevState) => ({
        ...prevState,
        email_token: res.data['email-token']
      }));
      clearInterval(verifyTimerTick);

    })
    .catch((err) => {
      if (err.response.status === 400) {
        openNotiDialog('인증번호가 올바르지 않습니다.');
      }
      else {
        openNotiDialog('오류가 발생했습니다.');
      }
    })
  }

  const handleSignup = () => {
    API.signup(signupInfo)
    .then((res) => {
      setPageState((prevState) => ({
        ...prevState,
        page: 'finish'
      }))
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  // etc
  const checkTerms = () => {
    const checkboxes = document.getElementsByName('cb');
    
    let isRequiredCleared: boolean = true;

    checkboxes.forEach((cb) => {
      if(!(cb as HTMLInputElement).checked) {        
        isRequiredCleared = false;
      }
    })

    setIsStepCleared(isRequiredCleared)
  }



  let verifyTimerTick:NodeJS.Timer;
  
  useEffect(() => {
    setIsStepCleared(false);
  }, [pageState.page])

  useEffect(() => {
    setIsStepCleared(
      signupState.isCodeVerified && 
      signupState.isEmailValidated && 
      signupState.isPasswordValidated && 
      signupState.isPasswordMatched && 
      signupInfo.name !== '' && 
      signupInfo.phone_first_number !== '' && 
      signupInfo.phone_middle_number !== '' &&
      signupInfo.phone_last_number !== '')
  }, [signupState, signupInfo])


  return (
    <div className='bluePaper'>

      {pageState.page === "term" ?
      <div className='mainCard' style={{marginTop: 70, marginBottom: 70}}>
        <div className='inter600-22-27 text-left' style={{color: '#004EA1', width: 836}}>
          약관 동의
        </div>

        <div className='flex items-center' style={{marginTop: 27, width: 836}}>
          <div>1. 이용 약관</div>
          <div className='ml-auto'>이용약관에 동의하십니까?</div>
          <input type="checkbox" style={{width: 16, height: 16, margin: 0, marginLeft: 13}} name='cb' id={`cb0`}
            onChange={(e) => {
              checkTerms();
            }}/>
          <div style={{marginLeft: 5}}>동의</div>
        </div>
        <div className='termTextBox' style={{marginTop: 26}}>
          {TERM}
        </div>

        <div className='flex items-center' style={{marginTop: 27, width: 836}}>
          <div>2. 개인정보 취급방침</div>
          <div className='ml-auto'>개인정보 취급방침에 동의하십니까?</div>
          <input type="checkbox" style={{width: 16, height: 16, margin: 0, marginLeft: 13}} name='cb' id={`cb1`}
            onChange={(e) => {
              checkTerms();
            }}/>
          <div style={{marginLeft: 5}}>동의</div>
        </div>
        <div className='termTextBox' style={{marginTop: 26}}>
          {TERM}
        </div>

        <div className='flex' style={{gap: 18, marginTop: 27}}>
          <button className='outlineBtn' onClick={() => navigate('/login')}>취소</button>
          <button className='acceptBtn' style={{opacity: isStepCleared ? 1 : 0.3, cursor: isStepCleared ? 'pointer' : 'default'}}
            onClick={() => {
              if (!isStepCleared) return;
              setPageState((prevState) => ({
                ...prevState,
                page: 'info'
              }))
            }}>다음</button>
        </div>
          
      </div> : null}




      {pageState.page === "info" ?
      <div className='mainCard' style={{marginTop: 70, marginBottom: 70}}>
      <div className='inter600-22-27 text-left' style={{color: '#004EA1', width: 836}}>
          정보 입력
        </div>

        <div style={{marginTop: 47, borderTop: '1px solid #4F4F4F', width: 836}}/>
        <div style={{width: 836}}>
          <div className='signupInfoRow'>
            <div>이름</div>
            <input 
              id='idInput'
              type='text'
              placeholder=''
              className='signupInfoInput'
              onChange={(e) => {
                setSignupInfo((prevState) => ({
                  ...prevState,
                  name: e.target.value
                }));
              }}
              >
            </input>
          </div>

          <div className='signupInfoRow'>
            <div>전화번호</div>
            <div style={{display: 'flex', gap: 10, lineHeight: '42px'}}>
              <input 
                id='phoneInput'
                type='text'
                maxLength={3}
                style={{width: 100}}
                placeholder='010'
                className='signupInfoInput'
                onChange={(e) => {
                  setSignupInfo((prevState) => ({
                    ...prevState,
                    phone_first_number: e.target.value
                  }));
                }}
                >
              </input>
              <>-</>
              <input 
                id='phoneInput'
                type='text'
                maxLength={4}
                style={{width: 100}}
                placeholder='1234'
                className='signupInfoInput'
                onChange={(e) => {
                  setSignupInfo((prevState) => ({
                    ...prevState,
                    phone_middle_number: e.target.value
                  }));
                }}
                >
              </input>
              <>-</>
              <input 
                id='phoneInput'
                type='text'
                maxLength={4}
                style={{width: 100}}
                placeholder='1234'
                className='signupInfoInput'
                onChange={(e) => {
                  setSignupInfo((prevState) => ({
                    ...prevState,
                    phone_last_number: e.target.value
                  }));
                }}
                >
              </input>
            </div>
          </div>

          <div className='signupInfoRow' style={{gridTemplateColumns: '125px 1fr 175px', borderBottom: 'none'}}>
            <div>소속기관 이메일</div>
            <input 
              id='emailInput'
              type='text'
              className='signupInfoInput'
              style={{
                width: 495
              }}
              onChange={(e) => {
                setSignupInfo((prevState) => ({
                  ...prevState,
                  email: e.target.value
                }));
                setSignupState((prevState) => ({
                  ...prevState,
                  isEmailValidated: validateEmail(e.target.value)
                }))
              }}
              >
            </input>
            <button className='acceptBtn' style={{width: 175, opacity: signupState.isCodeVerified || verifySec > 0 ? 0.3 : 1, cursor: signupState.isCodeVerified || verifySec > 0 ? 'default' : 'pointer'}}
              onClick={() => {
                if (signupState.isCodeVerified) return;
                if (verifySec > 0) {
                  return;
                }
                handleRequestVerify();
              }}>{!signupState.isVerifyRequested ? '인증번호 전송' : !signupState.isCodeVerified ? '인증번호 재전송' : '인증 완료'}</button>
          </div>

          {signupState.isVerifyRequested && !signupState.isCodeVerified ?
          <>
          <div className='signupInfoRow' style={{gridTemplateColumns: '125px 155px 80px 1fr', borderBottom: 'none', height: 60}}>
            <div>인증번호</div>
            <input 
              id='codeInput'
              type='text'
              className='signupInfoInput'
              style={{
                width: 139
              }}
              onChange={(e) => {
                setSignupState((prevState) => ({
                  ...prevState,
                  verifyCode: e.target.value
                }));
              }}
              >
            </input>
            <div className='inter500-16-20'>{`${Math.trunc(verifySec/60)}분 ${leftPad(verifySec%60)}초`}</div>

            <button className='acceptBtn' style={{width: 110, opacity: verifySec <= 0 ? 0.3 : 1, cursor: verifySec <= 0 ? 'default' : 'pointer'}}
              onClick={() => {
                if (verifySec <= 0) {
                  return;
                }
                handleCheckVerifyCode();
                
              }}>인증</button>
          </div>
          <div className='signupInfoRow' style={{height: 60, marginTop: -5}}>
            <div></div>
            <div style={{color: '#616161'}}>입력시간 초과시 재전송 버튼을 눌러주세요.</div>
          </div>
          </>
          : null}

          <div className='signupInfoRow' style={{borderBottom: 'none', gridTemplateColumns: '125px 426px 1fr 24px'}}>
            <div>비밀번호</div>
            <input 
              id='pwInput'
              type='password'
              className='signupInfoInput'
              placeholder='비밀번호  (password)'
              style={{width: 409}}
              onChange={(e) => {
                setSignupInfo((prevState) => ({
                  ...prevState,
                  password: e.target.value
                }));
                setSignupState((prevState) => ({
                  ...prevState,
                  isPasswordValidated: validatePassword(e.target.value)
                }))
                setSignupState((prevState) => ({
                  ...prevState,
                  isPasswordMatched: signupInfo.password_confirm === e.target.value
                }));
              }}
              >
            </input>
            <div>영문 + 숫자 + 특수문자 10자 이상</div>
            <div style={{width: 24, height: 24, marginLeft: -295}}>
              <img src={signupState.isPasswordValidated ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
            </div>
          </div>
          <div className='signupInfoRow' style={{gridTemplateColumns: '125px 426px 1fr 24px', marginTop: -10}}>
            <div>비밀번호 확인</div>
            <input 
              id='pwConfirmInput'
              type='password'
              className='signupInfoInput'
              placeholder='비밀번호 확인  ( Confirm password)'
              style={{width: 409}}
              onChange={(e) => {
                setSignupInfo((prevState) => ({
                  ...prevState,
                  password_confirm: e.target.value
                }));
                setSignupState((prevState) => ({
                  ...prevState,
                  isPasswordMatched: signupInfo.password === e.target.value
                }));
              }}
              >
            </input>
            <div></div>
            <div style={{width: 24, height: 24, marginLeft: -295}}>
              <img src={signupState.isPasswordMatched ? `./src/ic_checked_circle.png` : `./src/ic_unchecked_circle.png`} alt="checked" />
            </div>
          </div>
        </div>

        <div className='flex' style={{gap: 18, marginTop: 'auto'}}>
          <button className='outlineBtn' style={{width: 141}}
          onClick={() => {
              setPageState((prevState) => ({
                ...prevState,
                page: 'term'
              }));
            }}>이전으로</button>
          <button className='acceptBtn' style={{opacity: isStepCleared ? 1 : 0.3, cursor: isStepCleared ? 'pointer' : 'default'}}
            onClick={() => {
              handleSignup();
            }}>다음</button>
        </div>
          
      </div> : null}

      {pageState.page === "finish" ?
      <div className='mainCard' style={{marginTop: 70, marginBottom: 70, justifyContent: 'center', height: 619}}>
        <div className='inter600-22-27 text-center' style={{color: '#004EA1', width: 836}}>
          회원가입 신청 완료
        </div>

        <div style={{marginTop: 46, borderTop: '2px solid #004EA1', width: 413}}/>

        <div className='inter400-24-50 text-center' style={{marginTop: 35}}>
        회원가입 신청이 완료되었습니다.<br/>
        관리자 승인 이후 로그인이 가능합니다.<br/>
        관리자 승인 시 안내 메일을 보내드리겠습니다.<br/>
        감사합니다.<br/>
        </div>

        <button className='acceptBtn' style={{width: 276, marginTop: 56}} onClick={() => navigate('/') }>처음으로</button>



          
      </div> : null}

    </div>
  );
}