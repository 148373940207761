import {atom, selector, useRecoilState} from 'recoil';
import { DialogInfoState, UserInfoState } from './interfaces';


export const dialogInfoState = atom<DialogInfoState>({
  key: 'dialogInfo',
  default: {
    isOpen: false,
    title: '',
    desc: '',
    onAccept: () => {}
  }
});

export const userInfoState = atom<UserInfoState>({
  key: 'userInfo',
  default: {
    isLoaded: false,
    isLoggedIn: false,
    email: ''
  }
});