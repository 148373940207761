import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../globalState';
import CommonDialog from '../components/CommonDialog';
import { DialogInfoState } from '../interfaces';

export default function DialogController() {
  
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);


  return (
  <>
  {dialogState.isOpen ? 

    <CommonDialog />

    
  : null}
  </>
  );
}

