import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { DialogInfoState, Notice, PageState, SearchState } from '../../interfaces';
import { dialogInfoState } from '../../globalState';
import { dateToYMD, openNotiDialogWithSetter, searchAndUpdateData } from '../../util';
import * as API from '../../APIProvider'
import moment from 'moment';
import CommonPageNavi from '../../components/CommonPageNavi';

export default function NoticeListPage() {

  const navigate = useNavigate();

  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [searchState, setSearchState] = useState<SearchState>({
    keyword: ''
  });

  const [rawNoticeData, setRawNoticeData] = useState<Array<Notice>>([]);
  const [noticeData, setNoticeData] = useState<Array<Notice>>([]);
  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })

  const handleLoadNotice = () => {
    API.getAllNotice()
    .then((res) => {
      console.log(res);
      setNoticeData(res.data);
      setRawNoticeData(res.data);
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      })
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  };

  useEffect(() => {
    handleLoadNotice();
  }, [])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', background: 'white', paddingBottom: 50}}>
        <div className='flexStart' style={{width: 1200, marginTop: 38}}>
          <span className='inter800-18-20'>공지사항</span>
        </div>

        <div className='flexStart' style={{width: 1200, marginTop: 23}}>
            
          <input 
            id='searchInput'
            type='text'
            className='squareInput'
            placeholder='검색어를 입력해주세요'
            style={{width: 315}}
            onChange={(e) => {
              setSearchState((prevState) => ({
                ...prevState,
                keyword: e.target.value
              }))
              if (e.target.value === '') {
                setNoticeData(rawNoticeData);
              }
              else {
                searchAndUpdateData(e.target.value, rawNoticeData, setNoticeData)
              }
            }}
            />
          <img src={searchState.keyword === '' ? `./src/ic_search.png` : `./src/ic_search_reset.png`} alt="reset button" style={{width: 20, height: 20, cursor: 'pointer', marginLeft: -36}}
            onClick={() => {
              if (searchState.keyword === '') return;
              setSearchState((prevState) => ({
                ...prevState,
                keyword: ''
              }));
              setNoticeData(rawNoticeData);
            }}/>
          <button className='noticeWriteBtn' style={{marginLeft: 'auto'}}
            onClick={() => {
              navigate('/notice/new')
            }}/>
        </div>

        <div className='noticeTopRow' style={{marginTop: 25}}>
          <div>번호</div>
          <div>제목</div>
          <div>날짜</div>
          <div>작성자</div>
        </div>

        {noticeData?.slice(pageState.idx!*10+0, pageState.idx!*10+10).map((item, idx) => {
          return (
            <div className='noticeItemRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: idx+1 === noticeData.length ? '1px solid #4F4F4F' : 'none'}}
              onClick={() => {
                navigate(`/notice/detail/${item.id}`)
              }}> 
              <div>{item.id}</div>
              {
                moment().diff(moment(item.created_at), 'days') < 7
                ?
                <div className='flexStart'>
                  <>{item.title}</>
                  <img src={`./src/ic_new.png`} style={{width: 80, marginLeft: 29}} alt="new" />
                </div>
                :
                <div className='flexStart'>{item.title}</div>
              }
              <div>{dateToYMD(item.created_at)}</div>
              <div>{item.created_by.user_name}</div>
            </div>
          )
        })
        }


      <CommonPageNavi pageState={pageState} setPageState={setPageState}/>


      </div>
    </>
  );
}