import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import * as API from '../../APIProvider'
import { useRecoilState } from 'recoil';
import {
  DialogInfoState,
  Notice,
  SearchState,
  ShinyIndividualInfo,
  ShinyLoadingState,
  UserInfoState
} from '../../interfaces';
import { dialogInfoState, userInfoState } from '../../globalState';
import { dateToYMD, dateToYMDHHMM, openNotiDialogWithSetter } from '../../util';
import { isNumber } from 'lodash';


const API_HOST = process.env.REACT_APP_API_HOST;
//const RSHINY_HOST = process.env.REACT_APP_RSHINY_HOST;
const RSHINY_HOST = 'https://prod.pharos.or.kr/rshiny';
//const APP_HOST = process.env.REACT_APP_HOST;
const APP_HOST = 'https://prod.pharos.or.kr';
export default function IndividualInsightPage() {

  const [searchState, setSearchState] = useState<SearchState>({
    keyword: '',
    targetKey: 'user_email'
  });

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [loadingState, setLoadingState] = useState<ShinyLoadingState>({
    app_individual: false
  });

  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [subjectId, setSubjectId] = useState<number>();

  const [individualInfo, setIndividualInfo] = useState<ShinyIndividualInfo>({
    sex: 0,
    age: 0,
    address: ''
  })
  const [progress, setProgress] = useState<number>(0);
  
/*  const sendValueToShiny = () => {
    const element = document.getElementById("shinyFrame") as HTMLIFrameElement;
    element.contentWindow?.postMessage({
      call: 'sendValue',
      value: subjectId
    }, `${APP_HOST}/rshiny/app_individual/`)
  }*/

  const sendValueToShiny = () => {
    const element = document.getElementById("shinyFrame") as HTMLIFrameElement;
    element.contentWindow?.postMessage({
      call: 'sendValue',
      value: subjectId
    }, `${RSHINY_HOST}/app_individual/`)
  }

  useEffect(() => {
    if (!userState.isLoaded) return;

    if (!userState.isLoggedIn) {
      navigate('/login');
      return;
    }
    else if (!(userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager' || userState.role_name === 'clinical_researcher' || userState.role_name === 'clinician')) {
      navigate('/')
      return;
    }

    window.addEventListener('message', function(event:any) {
      var origin = event.origin || event.originalEvent.origin;
    
      if (origin !== APP_HOST) return;
      
      if (typeof event.data == 'object' && event.data.call=='sendValue') {
        if (event.data.value.i_age !== undefined) {
          console.log(event.data.value.i_age)
          setIndividualInfo((prevState) => ({
            ...prevState,
            age: event.data.value.i_age
          }))
        }
        else if (event.data.value.i_sex !== undefined) {
          setIndividualInfo((prevState) => ({
            ...prevState,
            sex: event.data.value.i_sex
          }))
        }
        else if (event.data.value.i_adress !== undefined) {
          setIndividualInfo((prevState) => ({
            ...prevState,
            address: event.data.value.i_adress
          }))
        }
        else if (event.data.value.progress !== undefined) {
          setProgress(event.data.value.progress)
        }
        else if (event.data.value.init_loaded) {
          setLoadingState((prevState) => ({
            ...prevState,
            app_individual: true
          }))
        }
      }
    })

  }, [userState.isLoaded])


  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', paddingBottom: 44}}>
        <div className='flexStart' style={{width: 1200, marginTop: 24, height: 45}}>
          <span className='inter800-20-20'>감염환자 임상정보</span>
        </div>

        <div id='individual-insight-page' className='mainGrid' style={{marginTop: 26, gap: 20, gridTemplateColumns: '285px 986px'}}>
          
          <div className='flexColumnsStart'>
            <form>
              <select className='squareSelect inter600-16-20' name="hospitalSelect" id="hospitalSelect" 
                value={searchState.targetKey}
                style={{width: 270, backgroundPosition: 230, backgroundImage: 'url(./src/ic_select_arrow.png)', marginTop: 0, color: 'black'}}
                onChange={(e) => {
                  setSearchState((prevState) => ({
                    ...prevState,
                    targetKey: e.target.value
                  }))
                }}>
                <option value="아주대학교의료원" selected>아주대학교의료원</option>
                <option value="분당서울대학교병원">분당서울대학교병원</option>
                <option value="서울아산병원">서울아산병원</option>
                <option value="가톨릭대학교 서울성모병원">가톨릭대학교 성모병원</option>
              </select>
              <input 
                id='searchInput'
                type="text"
                pattern="[0-9]*"
                value={subjectId}
                className='squareInput'
                placeholder='Subject ID'
                style={{marginTop: 13, width: 270}}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  setSubjectId(Number(e.target.value));
                }}
                />
              </form>
            <button className='acceptBtn self-center' style={{width: 250, marginTop: 17}}
              onClick={() => {
                if (subjectId === undefined || subjectId === 0) {
                  openNotiDialog("ID가 올바르지 않습니다.")
                  return;
                }

                setIsSearched(true);
                setProgress(0);
                sendValueToShiny();
              }}>
              검색
            </button>

            <div className='patientCard' style={{marginTop: 26}}>
              <div className='inter800-16-20' style={{marginTop: 18}}>환자정보</div>
              <div className='patientInnerCard inter500-14-20' style={{marginTop: 16}}>
                <div>성별</div>
                <div className='inter500-16-20' style={{color: 'black'}}>{individualInfo.sex}</div>
                <div>나이</div>
                <div className='inter500-16-20' style={{color: 'black'}}>{individualInfo.age}</div>
                <div>주소</div>
                <div className='inter500-16-20' style={{color: 'black'}}>{individualInfo.address}</div>
                <div>여행력</div>
                <div className='inter500-16-20' style={{color: 'black'}}>-</div>
                <div>방문경로</div>
                <div className='inter500-16-20' style={{color: 'black'}}>-</div>
              </div>


            </div>
            
          </div>


          

          <div style={{position: 'relative', width: 1030, height: 805, display: 'grid'}}>

            <div className='iframeCard' style={{width: 980, marginLeft: 'auto', visibility: isSearched ? 'visible' : 'hidden'}}>
              <iframe id='shinyFrame' src={`${RSHINY_HOST}/app_individual/?access_token=kkkkabcd`}
                style={{boxSizing: 'border-box'}}/>
            </div>

            {!isSearched ? 
             <img src={`./src/banner_individual.png`} style={{width: 1030, position: 'absolute', marginRight: 'auto'}}/>
            : null }

            {progress !== 100 && progress !== 0? 
            <div className='iframeLoadingCard' style={{background: 'none', border: 'none', height: '30%', marginTop: '25%'}}>
              Loading... - {progress}%
            </div> 
            : null}

          </div>

          
          
        </div>
      </div>


    </>
  );
}