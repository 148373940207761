import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogInfoState, Notice } from '../../interfaces';
import * as API from '../../APIProvider'
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { dateToYMD, openNotiDialogWithSetter } from '../../util';
import { isNumber } from 'lodash';


export default function NoticeDetailPage() {

  const navigate = useNavigate();
  const location = useLocation();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }
  const [notice, setNotice] = useState<Notice>();
  const [prevNotice, setPrevNotice] = useState<Notice>();
  const [nextNotice, setNextNotice] = useState<Notice>();


  const handleLoadNotice = (id: number) => {
    API.getAllNotice()
    .then((res) => {
      const notices = res.data;
      for (let i=0; i< notices.length; i++) {
        if (notices[i].id === id) {
          setNotice(notices[i]);
          if (i !== notices.length -1) {
            setPrevNotice(notices[i+1]);
          }
          else {
            setPrevNotice(undefined);
          }

          if (i !== 0) {
            setNextNotice(notices[i-1]);
          }
          else {
            setNextNotice(undefined);
          }

          break;
        }
      }
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  const handleDeleteNotice = (id: number) => {
    API.deleteNotice(id)
    .then((res) => {
      navigate('/notice/list')
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');
    })
  }

  useEffect(() => {
    if (!isNumber(Number(location.pathname.split('/').pop()))) navigate('/notice')
    handleLoadNotice(Number(location.pathname.split('/').pop()))
  }, [location.pathname])

  return (
    <>
      <div className='bluePaper flexColumnsStart' style={{height: 'auto', background: 'white', paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-18-20'>공지사항</span>
        </div>

        <div className='flexEnd contentWidth' style={{marginTop: 8}}>
          <button onClick={() => {
            navigate(`/notice/edit/${notice?.id}`)
          }}>
            <img src={`./src/btn_edit.png`} alt="수정" style={{width: 108, height: 42, marginRight: 27, cursor: 'pointer'}}/>
          </button>
          <button onClick={() => {
            setDialogState((prevState) => ({
              ...prevState,
              isOpen: true,
              title: '공지사항을 삭제하시겠습니까?',
              onAccept: () => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: false
                }))
                handleDeleteNotice(notice!.id)
              },
              onDeny: () => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: false
                }))
              }
            }));

            }}>
            <img src={`./src/btn_delete.png`} alt="삭제" style={{width: 108, height: 42, cursor: 'pointer'}}/>
          </button>
        </div>

        <div className='noticeContentTopRow' style={{marginTop: 24}}>
          <div className='inter400-16-20'>{notice?.id}</div>
          <div className='inter600-16-20'>{notice?.title}</div>
          <div className='inter400-14-20'>{dateToYMD(notice?.created_at!)}</div>
          <div style={{textAlign: 'right'}}>{notice?.created_by.user_name}</div>
        </div>

        
        <div className='noticeContentBox' style={{borderBottom: !nextNotice && !prevNotice ? '1px solid #4F4F4F' : 'none'}}>
          {notice?.content}
        </div>

        {nextNotice 
        ?
        <div className='noticeItemRow' style={{borderTop: '1px solid #4F4F4F', borderBottom: !prevNotice ? '1px solid #4F4F4F' : 'none'}}
          onClick={() => {
            navigate(`/notice/detail/${nextNotice.id}`)
          }}>
          <div>{nextNotice?.id}</div>
          <div>{nextNotice?.title}</div>
          <div>{dateToYMD(nextNotice?.created_at!)}</div>
          <div>{nextNotice?.created_by.user_name}</div>
        </div>
        : null}

        {prevNotice 
        ?
        <div className='noticeItemRow' style={{background: '#F2F2F2', borderBottom: '1px solid #4F4F4F', borderTop: !nextNotice ? '1px solid #4F4F4F' : 'none'}}
          onClick={() => {
            navigate(`/notice/detail/${prevNotice.id}`)
          }}>
          <div>{prevNotice?.id}</div>
          <div>{prevNotice?.title}</div>
          <div>{dateToYMD(prevNotice?.created_at!)}</div>
          <div>{prevNotice?.created_by.user_name}</div>
        </div>
        : null}


        <button className='acceptBtn' style={{marginTop: 47, width: 108}}
          onClick={() => {
            navigate('/notice')
          }}>목록</button>
        

      </div>
    </>
  );
}