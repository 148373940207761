import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState } from '../../globalState';
import { DialogInfoState, PageState, PopupInfoState, SearchState, StatRequestItem } from '../../interfaces';
import { dateToYMDHHMM, openNotiDialogWithSetter, searchAndUpdateDataWithTargetKey } from '../../util';
import CommonPageNavi from '../../components/CommonPageNavi';
import * as API from '../../APIProvider'


export default function AllStatsRequestsPage() {

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [statData, setStatData] = useState<Array<StatRequestItem>>();
  const [rawStatData, setRawStatData] = useState<Array<StatRequestItem>>([]);


  const [popupState, setPopupState] = useState<PopupInfoState>({
    isOpen: false,
    itemIdx: 0,
    type: 'request'
  });

  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })

  const [searchState, setSearchState] = useState<SearchState>({
    keyword: '',
    targetKey: 'sender_org_name'
  });

  const handleLoadAllStatHistory = () => {
    API.getAdminAllStats()
    .then((res) => {
      setStatData(res.data);
      setRawStatData(res.data);
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      });
      console.log(res)
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  useEffect(() => {
    handleLoadAllStatHistory();
  }, [])


  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>통계 요청 내역</span>
        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '36px 49px'}}>

        <div className='flexStart inter800-16-20' style={{width: 1174}}>
           
           <select className={cn('squareSelect')} name="searchType" id="searchTypeSelect" 
             value={searchState.targetKey}
             style={{backgroundImage: 'url(./src/ic_select_arrow.png)'}}
             onChange={(e) => {
               setSearchState((prevState) => ({
                 ...prevState,
                 targetKey: e.target.value
               }))
             }}>
             <option value="sender_org_name" selected>요청기관</option>
             <option value="cohort_name">코호트</option>
             <option value="recipient_org_name">수신기관</option>
           </select>
           <input 
             id='searchInput'
             type='text'
             className='squareInput'
             placeholder=''
             value={searchState.keyword}
             style={{marginLeft: 8}}
             onChange={(e) => {
               setSearchState((prevState) => ({
                 ...prevState,
                 keyword: e.target.value
               }))
               searchAndUpdateDataWithTargetKey(e.target.value, searchState.targetKey!, rawStatData, setStatData, setPageState)
             }}/>
           <img src={searchState.keyword === '' ? `./src/ic_search.png` : `./src/ic_search_reset.png`} alt="reset button" style={{width: 20, height: 20, cursor: 'pointer', marginLeft: -30}}
             onClick={() => {
               if (searchState.keyword === '') return;
               setSearchState((prevState) => ({
                 ...prevState,
                 keyword: ''
               }));
               setStatData(rawStatData);
             }}/>

         </div>

          <div className='adminStatRow' style={{borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600, marginTop: 39}}>
            <div>요청일시</div>
            <div>요청기관</div>
            <div>코호트</div>
            <div>요청 코멘트</div>
            <div>수신기관</div>
            <div>답신 코멘트</div>
            <div>처리 일시</div>
            <div>상태</div>

          </div>

          <form>
          {
            statData?.slice(pageState.idx!*10+0, pageState.idx!*10+10).map((item, idx, arr) => {
              return (
              <>
                <div className='adminStatRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: (idx+1 === arr.length || (idx+1) %10 === 0) ? '1px solid #4F4F4F' : 'none'}}>
                  <div>{dateToYMDHHMM(item.requested_at)}</div>
                  <div style={{display: 'flex', cursor: 'pointer'}}
                    onMouseEnter={() => {
                      setPopupState({
                        isOpen: true,
                        itemIdx: idx,
                        type: 'sender'
                      })
                    }}
                    onMouseLeave={() => {
                      setPopupState({
                        isOpen: false
                      })
                    }}>{item.sender_org_name}
                    <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                    </div>
                  <div style={{width: 120, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.cohort_name}<br/>({item.cohort_id})</div>
                  <div className='flex' style={{cursor: 'pointer'}}
                    onMouseEnter={() => {
                      setPopupState({
                        isOpen: true,
                        itemIdx: idx,
                        type: 'request'
                      })
                    }}
                    onMouseLeave={() => {
                      setPopupState({
                        isOpen: false
                      })
                    }}>
                    <>확인</>
                    <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                  </div>
                  <div>{item.recipient_org_name}</div>
                  {item.respond_comment === null || item.respond_comment === ''
                  ?
                  <div>-</div>
                  :
                  <div className='flex' style={{cursor: 'pointer'}}
                    onMouseEnter={() => {
                      if (item.respond_comment === null || item.respond_comment === '') return;
                      setPopupState({
                        isOpen: true,
                        itemIdx: idx,
                        type: 'response'
                      })
                    }}
                    onMouseLeave={() => {
                      setPopupState({
                        isOpen: false
                      })
                    }}
                    >
                    <>확인</>
                    <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                  </div>
                  }
                  
                  <div>{item.responded_at !== null ? dateToYMDHHMM(item.responded_at) : '-'}</div>
                  <div>
                    {
                      item.status === 'pending' ? '대기' : 
                      item.status === 'denied' ? <div style={{color: '#FF452C'}}>거부</div> : 
                      item.status === 'completed' ? <div style={{color: '#19C974'}}>완료</div> : 
                      item.status === 'data-processing' ? <div style={{color: '#314B9E'}}>전송중</div> :
                      '에러'
                     }
                     </div>

                  

                </div>
                <div className='floatingComment' style={{visibility: popupState.isOpen && popupState.itemIdx === idx ? 'visible' : 'hidden', 
                  marginLeft: popupState.type === 'request' ? 360 
                              : popupState.type === 'response' ? 600
                              : 100 }}>

                  {popupState.type === 'request' ? item.request_comment 
                  : popupState.type === 'response' ? item.respond_comment 
                  : item.sender_user_name ?? '-'}
                </div>
              </>
              )
            })
          }
          </form>


          <CommonPageNavi pageState={pageState} setPageState={setPageState}/>



        </div>

        

      </div>
    </>
  );
}