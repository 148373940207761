import { useEffect, useState } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dialogInfoState, userInfoState } from '../../globalState';
import { DialogInfoState, PageState, PopupInfoState, StatRequestItem, StatResponseInfo, UserInfoState } from '../../interfaces';
import { dateToYMDHHMM, onClickSelectAll, openNotiDialogWithSetter, updateSelectAll } from '../../util';
import * as API from '../../APIProvider'
import CommonPageNavi from '../../components/CommonPageNavi';
import { cloneDeep } from 'lodash';
import { AxiosResponse } from 'axios';
import * as XLSX from 'xlsx';

export default function StatsResponsePage() {

  const [userState, setUserState] = useRecoilState<UserInfoState>(userInfoState);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useRecoilState<DialogInfoState>(dialogInfoState);
  const openNotiDialog = (title: string) => {
    openNotiDialogWithSetter(setDialogState, title);
  }

  const [popupState, setPopupState] = useState<PopupInfoState>({
    isOpen: false,
    itemIdx: 0,
    type: 'request'
  });

  const [statData, setStatData] = useState<Array<StatRequestItem>>();
  const [pageState, setPageState] = useState<PageState>({
    idx: 0,
    total: 0
  })

  const [checkedStats, setCheckedStats] = useState<Array<StatResponseInfo>>([]);



  const handleLoadReceivedStats = () => {
    API.getReceivedStats()
    .then((res) => {
      setStatData(res.data);
      setCheckedStats(res.data.map((item:StatRequestItem) => {
        return ({
          checked: false,
          stat_id: item.id,
          reply: ''
        })
      }))
      setPageState({
        idx: 0,
        total: Math.ceil(res.data.length / 10)
      })
    })
    .catch((err) => {
      openNotiDialog('오류가 발생했습니다.');

    })
  };

  const handleResponseStats = async (agreed: boolean) => {
    try {

      let promises:Array<any> = [];

      checkedStats.forEach((item, idx) => {
        if (!item.checked) return;
        promises.push(API.updateReceivedStat(item.stat_id, agreed, item.reply))
      })

      await Promise.all(promises)
      .then((res) => {
        console.log(res);
        setDialogState((prevState) => ({
          ...prevState,
          isOpen: true,
          title: '통계 요청 처리를 완료했습니다.',
          onAccept: () => {
            setDialogState((prevState) => ({
              ...prevState,
              isOpen: false
            }))
            handleLoadReceivedStats();
            window.location.reload();
          },
          onDeny: undefined
        }));
  
      })
    }
    catch (e) {
      console.log(e);
      openNotiDialog('오류가 발생했습니다.');
    }
    
    
  };

  useEffect(() => {
    if (!userState.isLoaded) return;

    if (!userState.isLoggedIn) {
      navigate('/login');
      return;
    }
    else if (!(userState.role_name === 'system_admin' || userState.role_name === 'org_admin' || userState.role_name === 'org_data_manager')) {
      navigate('/')
      return;
    }

    handleLoadReceivedStats();
  }, [userState.isLoaded]);


  return (
    <>
      <div className='bluePaper' style={{paddingBottom: 50}}>
        <div className='flexStart contentWidth' style={{marginTop: 38}}>
          <span className='inter800-20-20'>통계 요청 처리</span>

        </div>

        <div className='mainWideCard' style={{marginTop: 28, height: 'auto', padding: '43px 49px 59px 49px'}}>

          <div className='flex gap-[12px]' style={{marginBottom: 35, alignSelf: 'flex-end'}}>
            <button className='acceptBtn self-center' style={{width: 110}}
              onClick={() => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  title: '선택된 통계 요청을 수락하시겠습니까?',
                  onAccept: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                    handleResponseStats(true);
                    handleLoadReceivedStats();
                  },
                  onDeny: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                  }
                }));
              }}>수락</button>
            <button className='outlineBtn self-center' style={{width: 110}}
              onClick={() => {
                setDialogState((prevState) => ({
                  ...prevState,
                  isOpen: true,
                  title: '선택된 통계 요청을 거부하시겠습니까?',
                  onAccept: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                    handleResponseStats(false);
                  },
                  onDeny: () => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      isOpen: false
                    }))
                  }
                }));
              }}>거부</button>
          </div>

          <div className='statResponseRow' style={{borderTop: '1px solid #4F4F4F', borderBottom: '2px solid #4F4F4F', fontWeight: 600}}>
            <div className='defaultCheckbox'>
              <input
                type='checkbox'
                name='cbAll'
                id={`cbAll`}
                onChange={(e) => {
                  onClickSelectAll(e.target.checked)
                }}
              />
              <label htmlFor={`cbAll`}></label>
            </div>
            <div>요청 일시</div>
            <div>요청 담당자</div>
            <div>코호트</div>
            <div>코멘트</div>
            <div>답신 코멘트</div>

          </div>

          <form>
          {
            statData?.map((item, idx) => {
              return (
                <>
                  <div className='statResponseRow' style={{background: idx %2 === 0 ? '#F2F2F2' : 'none', borderBottom: idx+1 === statData.length ? '1px solid #4F4F4F' : 'none'}}>
                    <div className='defaultCheckbox'>
                      <input
                        type='checkbox'
                        name='cb'
                        id={`cb${idx}`}
                        onChange={(e) => {
                          let stats = cloneDeep(checkedStats);
                          stats[idx].checked = e.target.checked;
                          setCheckedStats(stats);
                          updateSelectAll();
                        }}
                      />
                      <label htmlFor={`cb${idx}`}></label>
                    </div>
                    <div>{dateToYMDHHMM(item.requested_at)}</div>
                    <div>{item.sender_user_name}<br/>({item.sender_org_name})</div>
                    <div style={{width: 120, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.cohort_name}<br/>({item.cohort_id})</div>
                    <div className='flex' style={{cursor: 'pointer'}}
                      onMouseEnter={() => {
                        setPopupState({
                          isOpen: true,
                          itemIdx: idx,
                          type: 'request'
                        })
                      }}
                      onMouseLeave={() => {
                        setPopupState({
                          isOpen: false
                        })
                      }}
                      
                      >
                      <>확인</>
                      <img src={`./src/ic_popup.png`} alt="seacrh button" style={{width: 20, height: 20, marginLeft: 5}}/>
                    </div>
                    <input 
                      id='commentInput'
                      type='text'
                      placeholder='코멘트 입력'
                      className='commentInput'
                      onChange={(e) => {
                        let stats = cloneDeep(checkedStats);
                        stats[idx].reply = e.target.value;
                        setCheckedStats(stats);
                      }}
                      >
                    </input>
                  </div>
                  
                  <div className='floatingComment' style={{visibility: popupState.isOpen && popupState.itemIdx === idx ? 'visible' : 'hidden', marginLeft: popupState.type === 'request' ? 360 : 740}}>
                    {item.request_comment === "" ? "-" : item.request_comment }
                  </div>
                </>
              )}
            )
          }
          


          </form>

            




          <CommonPageNavi pageState={pageState} setPageState={setPageState}/>



        </div>

        

      </div>
    </>
  );
}